import { Column, EComponentSize, ITextFieldAction, ITextFieldMethods, Label, Row, SearchField } from '@hyperclap/ui';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useOutletContext, useParams, useSearchParams } from 'react-router-dom';

import { IconCloseLine, IconMagnifierLine } from '@assets/images/svg';
import { STICKER_SEARCH_QUERY_NAME } from '@common';
import { StickersListMode, useAppContext } from '@hooks';
import { useNavigator } from '@hooks/app/navigator';
import { ContentMarkAction, IContentMark, ISticker } from '@typings';
import { ITabParams, StickersList, Tabs } from '@views';

import s from './StickersSearch.scss';

enum SearchTextFieldAction {
    RESET = 'reset',
}

interface StickersSearchProps {
    setStickerToEdit?: (sticker: ISticker) => void,
}

export const StickersSearch = () => {
    const { setStickerToEdit } = useOutletContext<StickersSearchProps>();

    const app = useAppContext();
    const {
        translation: { t },
        contentMarks: {
            contentMarksByCategory,
        },
    } = app;

    const { goToModerationStickersTab } = useNavigator();

    const { mode, scrollIndex } = useParams();
    const [searchParams] = useSearchParams();
    const query = searchParams.get(STICKER_SEARCH_QUERY_NAME) ?? '';

    const searchInputMethodsRef = useRef<ITextFieldMethods>(null);

    const [selectedContentMarks, setSelectedContentMarks] = useState<IContentMark[]>([]);
    const [enteredSearchQuery, setEnteredSearchQuery] = useState(query || '');
    const [isHiddenFilters, setIsHiddenFilters] = useState(true);
    const [stickersListMode, setStickersListMode] =
        useState<StickersListMode>(mode
            ? StickersListMode[mode.toUpperCase() as keyof typeof StickersListMode]
            : StickersListMode.APPROVED);

    const onSearchTextFieldAction = (action: ITextFieldAction) => {
        if (action.name === SearchTextFieldAction.RESET) {
            setEnteredSearchQuery('');
        }
    };

    const searchFieldActions = [
        {
            icon: <IconCloseLine/>,
            name: SearchTextFieldAction.RESET,
            keyboardKeys: ['Escape'],
            hidden: !enteredSearchQuery,
        },
    ];

    const TABS: Array<ITabParams<StickersListMode>> = [
        { caption: t('moderator-area.stickers-list.approved'), tag: StickersListMode.APPROVED },
        { caption: t('moderator-area.stickers-list.blacklist'), tag: StickersListMode.BLACKLIST },
        { caption: t('moderator-area.stickers-list.blacklist-date'), tag: StickersListMode.BLACKLIST_DATE },
        { caption: t('moderator-area.stickers-list.moderation'), tag: StickersListMode.MODERATION },
        { caption: t('moderator-area.stickers-list.rejected'), tag: StickersListMode.REJECTED },
    ];

    const onTabSelected = (mode: StickersListMode = StickersListMode.APPROVED) => {
        goToModerationStickersTab(mode, enteredSearchQuery);
        setStickersListMode(mode);
    };

    const onContentMarkSelect = (contentMark: IContentMark) => {
        if (selectedContentMarks.some((m) => m._id === contentMark._id)) {
            setSelectedContentMarks(selectedContentMarks.filter((m) => m._id !== contentMark.id));
        } else {
            setSelectedContentMarks([...selectedContentMarks, contentMark]);
        }
    };

    const onDeleteMarkClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, contentMark: IContentMark) => {
        setSelectedContentMarks(selectedContentMarks.filter((m) => m._id !== contentMark.id));
        e.preventDefault();
        e.stopPropagation();
    };

    const onClearClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setSelectedContentMarks([]);
        e.preventDefault();
        e.stopPropagation();
    };

    useEffect(() => {
        if (enteredSearchQuery !== query) {
            goToModerationStickersTab(stickersListMode, enteredSearchQuery);
        } else {
            goToModerationStickersTab(stickersListMode, enteredSearchQuery, scrollIndex);
        }
    }, [enteredSearchQuery]);

    return (
        <>
            <Row className={s.blockRow} margin={{ top: 20 }}>
                <SearchField
                    tag={<IconMagnifierLine/>}
                    placeholder={t('moderator-area.stickers-list.find-stickers')}
                    defaultValue={enteredSearchQuery}
                    debounceTime={500}
                    actions={searchFieldActions}
                    methodsRef={searchInputMethodsRef}
                    onAction={onSearchTextFieldAction}
                    onChanged={setEnteredSearchQuery}
                />
            </Row>
            <Row>
                <Column grow>
                    <Row>
                        <div onClick={() => setIsHiddenFilters(!isHiddenFilters)} className={s.contentMarksControl}>
                            <div className={s.contentMarksControlArrow}>{isHiddenFilters ? '↓' : '↑'}</div>
                            {selectedContentMarks.length > 0 &&
                                <div onClick={(e) => onClearClick(e)} className={s.clearButton}>
                                    <IconCloseLine/>
                                </div>
                            }
                            <div>
                                {selectedContentMarks.length > 0
                                    ? (
                                        <div className={s.selectedContentMarks}>
                                            {selectedContentMarks.map((mark) => (
                                                <div
                                                    onClick={(e) => onDeleteMarkClick(e, mark)}
                                                    className={cn(s.item, {
                                                        [s.red]: mark.actions?.includes(ContentMarkAction.REJECT),
                                                        [s.twitch]: mark.actions?.includes(ContentMarkAction.TWITCH_UNSAFE),
                                                    })} key={mark.id}>
                                                    <div className={s.itemText}>{mark.name}</div>
                                                    <div className={s.itemDeleteButton}><IconCloseLine/>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )
                                    : (<>{t('moderator-area.stickers-search.filters')}</>)
                                }
                            </div>
                        </div>
                    </Row>
                    <Row className={cn(
                        {
                            [s.hidden]: isHiddenFilters,
                            [s.show]: !isHiddenFilters,
                        },
                    )}>
                        <Column style={{ marginTop: 15 }}>
                            <Row>
                                <Label
                                    padding={{ top: 0 }}
                                    caption={t('moderator-area.sticker-editor.content-marks')}
                                    size={EComponentSize.MEDIUM}
                                />
                            </Row>
                            {contentMarksByCategory && Object.keys(contentMarksByCategory)
                                .filter((c) => !contentMarksByCategory[c]
                                    .every((m) => selectedContentMarks.includes(m)))
                                .map((category) => (
                                    <Row key={category}>
                                        <Column grow>
                                            <Row>
                                                <Label
                                                    padding={{ top: 0 }}
                                                    caption={category}
                                                    size={EComponentSize.SMALL}
                                                />
                                            </Row>
                                            <Row grow>
                                                <div className={s.contentMarks}>
                                                    {contentMarksByCategory[category]
                                                        .filter((x) => !selectedContentMarks.includes(x))
                                                        .map((mark) => (
                                                            <div
                                                                onClick={() => onContentMarkSelect(mark)}
                                                                className={cn(s.item, {
                                                                    [s.red]: mark.actions?.includes(ContentMarkAction.REJECT),
                                                                    [s.twitch]: mark.actions?.includes(ContentMarkAction.TWITCH_UNSAFE),
                                                                    [s.selected]: selectedContentMarks.some((x) => x.id === mark.id),
                                                                })} key={mark.id}>
                                                                <div className={s.itemText}>{mark.name}</div>
                                                            </div>
                                                        ))}
                                                </div>
                                            </Row>
                                        </Column>
                                    </Row>
                                ))}
                        </Column>
                    </Row>
                </Column>
            </Row>
            <Row margin={{ top: 23 }}>
                <Column grow>
                    <Tabs activeTab={stickersListMode} onSelectionChange={onTabSelected} tabs={TABS}/>
                    <StickersList
                        filters={selectedContentMarks}
                        scrollIndex={scrollIndex ? parseInt(scrollIndex) : undefined}
                        searchQuery={enteredSearchQuery}
                        stickersListMode={stickersListMode}
                        setStickerToEdit={setStickerToEdit}/>
                </Column>
            </Row>
        </>
    );
};
