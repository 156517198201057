import { Logger } from '@hyperclap/ui';

import { IGiveBonusParams, IUser, IWalletState, TOptional } from '@typings';

import { baseApi } from './base.api';

const logger = new Logger({ target: 'UsersApi', showTimestamp: true });
logger.trace(`Configure Users API`);

/** Invites API provides methods are related users (streamers, viewers_) data */
export const usersApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [
            'Users_CurrentUser',
        ],
    })
    .injectEndpoints({
        endpoints: (build) => ({

            /** Loads current user data */
            currentUser: build.query<IUser, void>({
                query: () => ({
                    url: 'user/current',
                    method: 'GET',
                }),
                providesTags: ['Users_CurrentUser'],
            }),

            /** Loads a referrals of the current user */
            currentUserReferrals: build.query<IUser[], void>({
                query: () => ({
                    url: 'user/referals',
                    method: 'GET',
                }),
            }),

            /** Gets a user by username */
            getUserByName: build.query<IUser, TOptional<string>>({
                query: (username: string) => ({
                    url: 'user/find',
                    method: 'POST',
                    body: { username },
                }),
            }),

            /** Sends a bonus to the certain user (supporter) increasing his wallet balance */
            giveBonus: build.mutation<void, IGiveBonusParams>({
                query: (data) => ({
                    url: 'user/give-bonus',
                    method: 'POST',
                    body: data,
                }),
            }),

            /** Gets a balance of the user for the certain streamer */
            getBalanceByStreamerName: build.query<IWalletState, string>({
                query: (username?: string) => ({
                    url: 'user/balance',
                    method: 'POST',
                    body: { username },
                }),
            }),

            /** Earns welcome bonus and update current balance */
            earnWelcomeBonus: build.mutation<IWalletState, string>({
                query: (streamerId?: string) => ({
                    url: 'user/earn-welcome-bonus',
                    method: 'POST',
                    body: { streamerId },
                }),
            }),

            /** Searches a streamer by name (suggestion list) */
            findStreamer: build.query<IUser[], string>({
                query: (value: string) => ({
                    url: 'user/find/streamer',
                    method: 'POST',
                    body: { value },
                }),
            }),

            updateUserName: build.mutation<IUser, string>({
                query: (value: string) => ({
                    url: 'user/name',
                    method: 'POST',
                    body: { value },
                }),
                invalidatesTags: ['Users_CurrentUser'],
            }),
        }),
    });
