import { Button, Column, Loader, Row } from '@hyperclap/ui';
import cn from 'classnames';
import React from 'react';

import { IconMagnifierLine } from '@assets/images/svg';
import { DATE_FORMAT_SHORT } from '@common';
import { TApp, useStreamerInfoMoney } from '@hooks';
import { useNavigator } from '@hooks/app/navigator';
import { EventKind, IStreamerInfoMoneyEventItem, IUser } from '@typings';
import { Empty } from '@views';

import s from './MoneyTable.scss';

export interface IMoneyTableProps extends TApp {
    streamer?: IUser;
}

export const MoneyTable = (props: IMoneyTableProps) => {
    const {
        translation: {
            t,
        },
        streamer,
    } = props;

    const {
        currentStats,
        isLoadingPage,
        isFetchingPage,
        isEndReached,
        loadNextPage,
    } = useStreamerInfoMoney({ streamer });

    const { goToViewersSearch } = useNavigator();

    const getEventRowClassname = (event: IStreamerInfoMoneyEventItem) => {
        switch (event.kind) {
            case EventKind.BONUS_EARNED:
            case EventKind.WELCOME_BONUS_EARNED:
            case EventKind.BONUS_ISSUED:
                return 'eventBgBonus';
            case EventKind.BUY_CURRENCY:
                return 'eventBgPurchase';
            case EventKind.STICKER_SENT:
                return event.fullscreen ? 'eventBgFullscreen' : 'eventBgSticker';
            case EventKind.MEME_CANNON_STICKER_SENT:
                return 'eventBgMemeCannon';
            default:
                return '';
        }
    };

    const getEventCellClassname = (event: IStreamerInfoMoneyEventItem) => {
        switch (event.kind) {
            case EventKind.STICKER_SENT:
                return event.fullscreen ? 'eventFgFullscreen' : 'eventFgSticker';
            case EventKind.MEME_CANNON_STICKER_SENT:
                return 'eventFgMemeCannon';
            default:
                return '';
        }
    };

    const getEventDirection = (item: IStreamerInfoMoneyEventItem) => {
        const kind = item.kind;

        return kind === EventKind.BUY_CURRENCY
            ? t('moderator-area.streamers.view.money-table.purchase') : t('moderator-area.streamers.view.money-table.bonus');
    };

    const formatDate = (date: Date | number) => {
        const d = typeof date === 'number' ? new Date(date) : date;

        return d.toLocaleDateString('ru', DATE_FORMAT_SHORT);
    };

    const formatDateTime = (date: Date | number) => {
        const d = typeof date === 'number' ? new Date(date) : date;

        return d.toLocaleTimeString('ru', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        });
    };

    const prepareRows = () => {
        const rows = [];
        let groupIdx = 0;

        for (const group of currentStats) {
            rows.push(
                <tr key={groupIdx} className={cn(s.eventsTableBodyRow, s.eventsTableBodyRowGroup)}>
                    <td className={s.eventsTableBodyRowCell}>{formatDate(group.timestamp)}</td>
                    <td className={s.eventsTableBodyRowCell}></td>
                    <td className={s.eventsTableBodyRowCell}></td>
                    <td className={s.eventsTableBodyRowCell}></td>
                    <td className={s.eventsTableBodyRowCell}></td>
                    <td className={s.eventsTableBodyRowCell}>{group.money}</td>
                </tr>,
            );

            let eIdx = 0;

            for (const e of group.events) {
                const rowClass = getEventRowClassname(e);
                const cellClass = getEventCellClassname(e);

                rows.push(
                    <tr key={groupIdx + '-' + eIdx} className={cn(s.eventsTableBodyRow, s[rowClass])}>
                        <td className={s.eventsTableBodyRowCell}>{formatDateTime(e.timestamp)}</td>
                        <td className={cn(s.eventsTableBodyRowCell, s[cellClass])}>{getEventDirection(e)}</td>
                        <td
                            className={cn(
                                s.eventsTableBodyRowCell,
                                s.eventsTableBodyRowCellClickable,
                                s[cellClass],
                            )}
                            onClick={() => goToViewersSearch(e.userId)}
                        >
                            {e.userName || e.userAlias}
                        </td>
                        <td
                            className={cn(s.eventsTableBodyRowCell, s.eventsTableBodyRowCellClickable, s[cellClass])}
                            onClick={() => goToViewersSearch(e.userId)}
                        >{e.userId}</td>
                        <td className={cn(s.eventsTableBodyRowCell, s[cellClass])}>{e.packValue || e.bonusValue}</td>
                        <td className={cn(s.eventsTableBodyRowCell, s[cellClass])}>{e.packPrice}</td>
                    </tr>,
                );
                eIdx++;
            }

            groupIdx++;
        }

        return rows;
    };

    return (
        <Column grow>
            <table className={s.eventsTable}>
                <thead>
                    <tr className={s.eventsTableHeadRow}>
                        <td className={s.eventsTableHeadRowCell}>
                            {t('moderator-area.streamers.view.money-table.date')}
                        </td>
                        <td className={s.eventsTableHeadRowCell}>
                            {t('moderator-area.streamers.view.money-table.type')}
                        </td>
                        <td className={s.eventsTableHeadRowCell}>
                            {t('moderator-area.streamers.view.money-table.viewer')}
                        </td>
                        <td className={s.eventsTableHeadRowCell}>
                            {t('moderator-area.streamers.view.money-table.viewer-id')}
                        </td>
                        <td className={s.eventsTableHeadRowCell}>
                            {t('moderator-area.streamers.view.money-table.coins')}
                        </td>
                        <td className={s.eventsTableHeadRowCell}>
                            {t('moderator-area.streamers.view.money-table.money')}
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {prepareRows()}
                </tbody>
            </table>
            {!isEndReached && currentStats.length > 0 &&
                !isLoadingPage && !isFetchingPage &&
                <Row>
                    <Button
                        caption={t('moderator-area.viewers.view.load-more')}
                        style={{ marginTop: 15 }}
                        className={s.loadMoreButton}
                        onClick={loadNextPage}
                    />
                </Row>
            }
            {!(isLoadingPage || isFetchingPage) && currentStats.length === 0 &&
                <Row>
                    <Empty
                        style={{ marginTop: 20 }}
                        icon={<IconMagnifierLine/>}
                        text={t('moderator-area.viewers.view.wallets-table.empty')}
                    />
                </Row>
            }
            {(isLoadingPage || isFetchingPage) &&
                <Row>
                    <Loader style={{ marginTop: 20, marginBottom: 3 }}/>
                </Row>
            }
        </Column>
    );
};
