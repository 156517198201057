import { Column, EColumnAlignment, Loader, Row, useModals } from '@hyperclap/ui';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';

import { IconCloseLine, IconImageSearch } from '@assets/images/svg';
import { useSimilarStickers } from '@hooks';
import { ISticker } from '@typings';
import { Empty, ITabParams, StickersGrid, Tabs } from '@views/fragments';

import s from './SimilarStickersPopup.scss';

interface SimilarStickersPopupProps {
    sticker: ISticker,
}

enum ViewMode {
    TOTAL,
    VIDEO,
    AUDIO,
}

export const SimilarStickersPopup = (props: SimilarStickersPopupProps) => {
    const { closeModal } = useModals();
    const {
        sticker,
    } = props;

    const {
        stickers,
        isLoading,
        search,
    } = useSimilarStickers();

    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.TOTAL);
    const [totalStickers, setTotalStickers] = useState<ISticker[]>([]);
    const [videoStickers, setVideoStickers] = useState<ISticker[]>([]);
    const [audioStickers, setAudioStickers] = useState<ISticker[]>([]);
    const [currentStickers, setCurrentStickers] = useState(totalStickers);

    const TABS: Array<ITabParams<ViewMode>> = [
        { caption: t('similar-stickers-popup.total'), tag: ViewMode.TOTAL },
        { caption: t('similar-stickers-popup.video'), tag: ViewMode.VIDEO },
        { caption: t('similar-stickers-popup.audio'), tag: ViewMode.AUDIO },
    ];

    const onTabSelected = (mode: ViewMode = ViewMode.TOTAL) => {
        setViewMode(mode);

        switch (mode) {
            case ViewMode.TOTAL:
                setCurrentStickers(totalStickers);
                break;
            case ViewMode.VIDEO:
                setCurrentStickers(videoStickers);
                break;
            case ViewMode.AUDIO:
                setCurrentStickers(audioStickers);
                break;
        }
    };

    useEffect(() => {
        if (sticker) {
            search(sticker);
        }
    }, [sticker]);

    useEffect(() => {
        if (!isLoading && stickers) {
            setTotalStickers(stickers.total.map(([sticker]) => (sticker)));
            setVideoStickers(stickers.v.map(([sticker]) => (sticker)));
            setAudioStickers(stickers.a.map(([sticker]) => (sticker)));

            switch (viewMode) {
                case ViewMode.TOTAL:
                    setCurrentStickers(stickers.total.map(([sticker]) => (sticker)));
                    break;
                case ViewMode.VIDEO:
                    setCurrentStickers(stickers.v.map(([sticker]) => (sticker)));
                    break;
                case ViewMode.AUDIO:
                    setCurrentStickers(stickers.a.map(([sticker]) => (sticker)));
                    break;
            }
        }
    }, [stickers, isLoading]);

    return (
        <Column grow className={s.similarStickersPopup}>
            <Column grow className={s.similarStickersPopupContent} alignment={EColumnAlignment.BEGIN}>
                <Row>
                    <Tabs activeTab={viewMode} onSelectionChange={onTabSelected} tabs={TABS}/>
                </Row>
                <Row grow className={s.stickers}>
                    {currentStickers && currentStickers.length > 0
                        ? <StickersGrid
                            className={s.stickersGrid}
                            isMobileLayoutRecommended={false}
                            stickers={currentStickers}
                            stickersSoundEnabled={true}
                        />
                        : isLoading
                            ? <Loader/>
                            : <Empty text={t('moderator-area.common.nothing-found')} icon={<IconImageSearch/>}/>
                    }
                </Row>
                <div className={s.closeButton} onClick={closeModal}>
                    <IconCloseLine/>
                </div>
            </Column>
        </Column>
    );
};
