export type TLanguageShortName = 'ru' | 'en';
export type TLanguageName = 'Русский' | 'English';

export interface ILanguage {
    name: TLanguageName;
    shortName: TLanguageShortName;
}

export const DEFAULT_SYSTEM_LANGUAGES: Array<ILanguage> = [
    { name: 'Русский', shortName: 'ru' },
    { name: 'English', shortName: 'en' },
];

export const DEFAULT_SYSTEM_LANGUAGE: ILanguage = {
    name: 'Русский',
    shortName: 'ru',
};

export const LANGUAGE_COOKIE_NAME = 'lang';
