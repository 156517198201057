import { Button, Column, EComponentColor, Label, Row, TextField, useModals } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import { t } from 'i18next';
import React, { useState } from 'react';

import { IconCloseLine } from '@assets/images/svg';

import s from './DeleteOrRestoreUserPopup.scss';

interface IDeleteOrRestoreUserPopupProps {
    userId: string;
    buttonCaption: string;
    onAcceptClick?: (userId: string) =>void;
}

export const DeleteOrRestoreUserPopup = (props: IDeleteOrRestoreUserPopupProps) => {
    const { closeModal } = useModals();
    const {
        userId,
        buttonCaption,
        onAcceptClick = noop,
    } = props;

    const [enteredId, setEnteredId] = useState('');

    const onButtonClick = () => {
        if (enteredId === userId) {
            onAcceptClick(userId);
            closeModal();
        }
    };

    return (
        <div className={s.deleteOrRestoreUserPopup}>
            <Column className={s.deleteOrRestoreUserPopupContent} rowGap={10}>
                <Label caption={t('delete-or-restore-popup.title')} className={s.deleteOrRestoreUserPopupTopicLabel} />
                <Row className={s.blockRow}>
                    <TextField placeholder={t('delete-or-restore-popup.user-id')} onChanged={(val) => setEnteredId(val?.toString() ?? '')}/>
                </Row>
                <Row className={s.footerRow} columnGap={10}>
                    <Column>
                        <Button
                            color={EComponentColor.DANGER}
                            disabled={enteredId !== userId}
                            caption={buttonCaption}
                            onClick={onButtonClick}
                        />
                    </Column>
                    <Column>
                        <Button
                            color={EComponentColor.ACCENT}
                            caption={t('common.cancel')}
                            onClick={closeModal}
                        />
                    </Column>
                </Row>
            </Column>

            <div className={s.closeButton} onClick={closeModal}>
                <IconCloseLine/>
            </div>
        </div>
    );
};
