import { useEffect } from 'react';

import { useNavigator } from '@hooks/app/navigator';
import { api } from '@store';
import { IUser } from '@typings';

export interface ModerationShiftParams {
    currentUser?: IUser;
    active?: boolean;
}

export const useModerationShift = (params: ModerationShiftParams) => {
    const { currentUser, active = false } = params;

    const {
        moderation: {
            useGetCurrentModerationShiftQuery,
            useOpenModerationShiftMutation,
            useCloseModerationShiftMutation,
            useLazyGetModeratedStickersCountQuery,
        },
    } = api;

    const {
        data: currentModerationShift,
        isFetching: isCurrentModerationShiftFetching,
    } = useGetCurrentModerationShiftQuery(undefined, { skip: !active });

    const [
        loadQueueCount,
        {
            data: queueLength,
        },
    ] = useLazyGetModeratedStickersCountQuery();

    const { goToModerationRoom, goToModerationStickersTab } = useNavigator();

    const [openModerationShift] = useOpenModerationShiftMutation();

    const [closeModerationShift] = useCloseModerationShiftMutation();

    const openShift = async () => {
        if (currentModerationShift?.isOpen) return;

        await openModerationShift().unwrap();

        goToModerationRoom();
    };

    const closeShift = async () => {
        if (!currentModerationShift?.isOpen) return;

        await closeModerationShift().unwrap();

        if (currentUser?.flags.isMasterModer || currentUser?.flags.isAdmin) {
            goToModerationStickersTab();
        }
    };

    const toggleShift = async () => {
        return currentModerationShift?.isOpen ? await closeShift() : await openShift();
    };

    useEffect(() => {
        if (currentUser) {
            loadQueueCount();
        }
    }, [currentUser]);

    return {
        currentUser,
        currentModerationShift,
        queueLength,
        isCurrentModerationShiftFetching,

        openShift,
        closeShift,
        toggleShift,
    };
};
