import { useEffect, useState } from 'react';

import { api } from '@store';
import { IStreamerInfoMoneyItem, IUser } from '@typings';

export interface IStreamerInfoMoneyParams {
    streamer?: IUser;
}

export const useStreamerInfoMoney = (params: IStreamerInfoMoneyParams) => {
    const { streamer } = params;
    const {
        moderation: {
            useLazyGetStreamerInfoMoneyPageQuery,
        },
    } = api;

    const [page, setPage] = useState(1);
    const [currentStats, setCurrentStats] = useState<IStreamerInfoMoneyItem[]>([]);
    const [isEndReached, setIsEndReached] = useState(false);
    const [displayErrors, setDisplayErrors] = useState(false);

    const [
        loadPage,
        {
            data: loadedPage,
            isLoading: isLoadingPage,
            isSuccess,
            isFetching: isFetchingPage,
        },
    ] = useLazyGetStreamerInfoMoneyPageQuery();

    const load = () => {
        if (streamer) {
            loadPage({ page: 1, streamerId: streamer?.id });
            setDisplayErrors(false);
        } else {
            setDisplayErrors(true);
            setCurrentStats([]);
        }
    };

    const loadNextPage = () => {
        setPage(page + 1);
    };

    useEffect(() => {
        setDisplayErrors(false);
        setIsEndReached(false);
        setCurrentStats([]);
        setPage(1);
        load();
    }, [streamer]);

    useEffect(() => {
        if (streamer && page > 1) {
            loadPage({ page, streamerId: streamer?.id });
        }
    }, [page]);

    useEffect(() => {
        if (loadedPage && !loadedPage.length) {
            setIsEndReached(true);
        } else {
            if (page === 1) {
                setCurrentStats(loadedPage ?? []);
            } else {
                setCurrentStats((prev) => [...prev].concat(loadedPage ?? []));
            }
        }
    }, [loadedPage]);

    return {
        currentStats,
        isLoadingPage,
        isFetchingPage,
        isEndReached,
        isSuccess,
        displayErrors,

        loadNextPage,
        load,
    };
};
