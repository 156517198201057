import { useApi, useAuth } from '@hooks';

export const useStreamerReferralSettings = () => {
    const {
        users: {
            useCurrentUserReferralsQuery,
        },
    } = useApi();

    const { authToken } = useAuth();

    const {
        data: currentUserReferrers,
        refetch: refetchCurrentUserReferrers,
    } = useCurrentUserReferralsQuery( void(0), { skip: !authToken });

    return {
        currentUserReferrers,
        refetchCurrentUserReferrers,
    };
};
