import {
    EOrientation,
    ERelativePosition,
} from '@hyperclap/ui';
import React from 'react';
import { useMatch } from 'react-router-dom';

import {
    IconFilterLine,
    IconSmileGrinLine,
    IconUserCircleLine,
    IconUsersAltLine,
} from '@assets/images/svg';
import { Menu, MenuItem, MenuItemProps } from '@components';
import { TApp } from '@hooks';

import s from './LeftSideMenu.scss';


interface LeftSideMenuProps extends TApp {
    hideMenuActivation?: boolean;
}

const defaultMenuItemProps: MenuItemProps<string> = {
    className: s.leftSideMenuItem,
    iconClassName: s.leftSideMenuItemIcon,
    iconPosition: ERelativePosition.LEFT,
};

export const LeftSideMenu = (props: LeftSideMenuProps) => {
    const {
        navigation: {
            goToPath,
        },
        translation: {
            t,
        },
        hideMenuActivation,
    } = props;

    const menuStructure: MenuItemProps<string>[] = [
        {
            active: !!useMatch('/moderation/stickers/*') && !hideMenuActivation,
            caption: t('moderator-area.menu.stickers'),
            icon: <IconSmileGrinLine/>,
            tag: '/moderation/stickers/search/approved',
            ...defaultMenuItemProps,
        },
        {
            active: !!useMatch('/moderation/viewers/*') && !hideMenuActivation,
            caption: t('moderator-area.menu.viewers'),
            icon: <IconUsersAltLine/>,
            tag: '/moderation/viewers',
            ...defaultMenuItemProps,
        },
        {
            active: !!useMatch('/moderation/streamers/*') && !hideMenuActivation,
            caption: t('moderator-area.menu.streamers'),
            icon: <IconUserCircleLine/>,
            tag: '/moderation/streamers',
            ...defaultMenuItemProps,
        },
        // {
        //     active: !!useMatch('/moderation/tags') && !hideMenuActivation,
        //     caption: t('moderator-area.menu.tags'),
        //     icon: <IconTagAltLine/>,
        //     iconClassName: s.favortitesMenuItemIconSizeFix,
        //     tag: '/moderation/tags',
        //     ...defaultMenuItemProps,
        // },
        {
            active: !!useMatch('/moderation/filters') && !hideMenuActivation,
            caption: t('moderator-area.menu.filters'),
            icon: <IconFilterLine/>,
            tag: '/moderation/filters',
            ...defaultMenuItemProps,
        },
    ];

    const onMenuItemClick = (tag?: string) => {
        if (tag) {
            goToPath(tag);
        }
    };

    const menuItems = menuStructure.map((itemProps, idx) => (
        <MenuItem key={idx} {...itemProps} onClick={onMenuItemClick}/>
    ));

    return (
        <Menu orientation={EOrientation.VERTICAL} className={s.leftSideMenu}>
            {menuItems}
        </Menu>
    );
};
