import { noop } from '@hyperclap/utils';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { IconCorruptedImageLine } from '@assets/images/svg';

import s from './Video.scss';

interface VideoProps {
    className?: string;
    disabled?: boolean;
    isHovered?: boolean;
    loop?: boolean;
    restartOnHover?: boolean;
    source?: string;
    volume?: number;
    withSound?: boolean;
    videoRef?: React.MutableRefObject<HTMLVideoElement | null | undefined>;
    stickerVideoRef?: React.MutableRefObject<HTMLVideoElement | null | undefined>;
    maxWidth?: number;
    maxHeight?: number;
    onReadyStateChange?: (ready: boolean) => void;
}

export const Video = (props: VideoProps) => {
    const {
        className,
        source,
        loop,
        disabled,
        volume = 100,
        isHovered = false,
        restartOnHover = false,
        withSound = true,
        videoRef,
        stickerVideoRef,
        maxHeight,
        maxWidth,
        onReadyStateChange = noop,
    } = props;

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [aspectRatio, setAspectRatio] = useState(1);
    const [styles, setStyles] = useState({});

    const video = useRef<HTMLVideoElement | null>();

    const onStartLoad = () => {
        setError(false);
        setLoaded(false);
        onReadyStateChange(false);
    };

    const onLoaded = () => {
        setLoaded(true);
        onReadyStateChange(true);
    };

    const onMetadataLoaded = () => {
        if (video.current?.videoWidth && video.current?.videoHeight) {
            setAspectRatio(video.current.videoWidth / video.current.videoHeight);
        }
    };

    const onError = () => {
        setError(true);
        onReadyStateChange(false);
    };

    useEffect(() => {
        if (video.current) {
            video.current.volume = volume / 100;
        }
    }, [volume]);

    useEffect(() => {
        if (video.current && restartOnHover && video.current.src) {
            video.current.currentTime = 0;
        }
    }, [isHovered, withSound]);

    useEffect(() => {
        if (maxHeight && maxWidth) {
            console.log(`maxWidth: ${maxWidth}, maxHeight: ${maxHeight}, aspectRatio: ${aspectRatio}`);

            if (aspectRatio <= maxWidth / maxHeight) {
                setStyles({ maxHeight, maxWidth: 'auto' });
            } else {
                setStyles({ maxWidth, maxHeight: 'auto' });
            }
        }
    }, [aspectRatio]);

    return (
        <div
            className={cn(
                s.videoContainer,
                className,
            )}
            style={styles}
        >
            {/* Show error if the image could not be loaded */}
            { !loaded && error &&
                <div className={s.error}>
                    <div className={s.errorImage}>
                        <IconCorruptedImageLine/>
                    </div>
                </div>
            }

            {/* This is tha main animation that is shown on collection */}
            { !error &&
                <video
                    className={cn(
                        s.video,
                        {
                            [s.hidden]: !loaded || error,
                            [s.videoDisabled]: disabled,
                            [s.videoHeightBasedAspect]: maxWidth && maxHeight && aspectRatio <= maxWidth / maxHeight,
                        },
                    )}
                    playsInline
                    autoPlay
                    muted={!withSound}
                    loop={loop && !isHovered}
                    controls={false}
                    src={source}
                    ref={(el) => {
                        video.current = el;
                        if (videoRef) videoRef.current = el;
                        if (stickerVideoRef) stickerVideoRef.current = el;
                    }}
                    onLoadStart={() => onStartLoad()}
                    onLoadedData={() => onLoaded()}
                    onLoadedMetadata={onMetadataLoaded}
                    onError={() => onError()}
                />
            }
        </div>
    );
};
