import { IComponentProps, useDeviceInfo } from '@hyperclap/ui';
import cn from 'classnames';
import React from 'react';

import { DEFAULT_MAX_CONTENT_WIDTH, DEFAULT_SIDEBAR_WIDTH } from '@common';
import { BackgroundMode } from '@typings';

import s from './DesktopPage.scss';


interface IResponsivePageProps extends IComponentProps {
    /** Additional style classes to apply to the root element of the component */
    className?: string;

    /** Optional footer element */
    footer?: React.ReactElement;

    /** Optional header element */
    header?: React.ReactElement;

    /** Hides left sidebar element */
    hideLeftSidebar?: boolean;

    /** Hides right sidebar element */
    hideRightSidebar?: boolean;

    /** Left sidebar optional content */
    leftSidebar?: React.ReactElement;

    /** Maximum width in pixels of the area which will contain any children */
    maxContentAreaWidth?: number;

    /** Page background picture */
    pageBackgroundUrl?: string;

    /** Page background picture mode (0 - cover, 1 - tile) */
    pageBackgroundMode?: BackgroundMode;

    /** Right sidebar optional content */
    rightSidebar?: React.ReactElement;

    /** Fixed width in pixels of two side areas */
    sidebarWidth?: number;

    /** Header is stuck to upper edge of viewport */
    stickyHeader?: boolean;
}

export const DesktopPage = (props: React.PropsWithChildren<IResponsivePageProps>) => {
    const {
        children,
        className,
        footer,
        header,
        hideLeftSidebar,
        hideRightSidebar,
        leftSidebar,
        maxContentAreaWidth = DEFAULT_MAX_CONTENT_WIDTH,
        pageBackgroundMode = BackgroundMode.COVER,
        pageBackgroundUrl,
        rightSidebar,
        sidebarWidth = DEFAULT_SIDEBAR_WIDTH,
        stickyHeader,
        style,
    } = props;
    const { isMobileLayoutRecommended } = useDeviceInfo();

    const pageStyles = pageBackgroundUrl
        ? {
            ...style,
            backgroundImage: `url(${pageBackgroundUrl})`,
            backgroundPosition: pageBackgroundMode ? '0 0' : 'center',
            backgroundSize: pageBackgroundMode ? 'center center' : 'cover',
        }
        : {
            ...style,
        };

    const contentAreaStyles = {
        maxWidth: maxContentAreaWidth + (hideRightSidebar ? sidebarWidth : 0) +
            (hideLeftSidebar ? sidebarWidth: 0) - (hideRightSidebar || hideLeftSidebar ? 24 : 0),
        paddingRight: (hideRightSidebar ? 24 : 0),
    };

    const sidebarStyles = sidebarWidth > 0
        ? {
            minWidth: sidebarWidth,
            maxWidth: sidebarWidth,
        }
        : {};

    return (
        <div
            className={cn(
                s.responsivePage,
                { [s.responsivePageDesktop]: !isMobileLayoutRecommended },
                className,
            )}
            style={pageStyles}
        >
            <div className={s.responsivePageContent}>
                <div className={cn(
                    s.responsivePageHeaderContainer,
                    { [s.responsivePageHeaderContainerSticked]: stickyHeader },
                )}>
                    {header}
                </div>
                <div className={s.responsivePageBodyContainer}>
                    {!hideLeftSidebar &&
                        <div style={sidebarStyles} className={s.responsivePageSidebarContainer}>{leftSidebar}</div>
                    }
                    <div style={contentAreaStyles} className={s.responsivePageContentContainer}>{children}</div>
                    {!hideRightSidebar &&
                        <div style={sidebarStyles} className={s.responsivePageSidebarContainer}>{rightSidebar}</div>
                    }
                </div>
                <div className={s.responsivePageFooterContainer}>
                    {footer}
                </div>
            </div>
        </div>
    );
};
