import { EComponentSize, Icon } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import React from 'react';

import { IconMonitorAltLine, IconSoundActiveLine, IconSoundMuteLine } from '@assets/images/svg';
import { ISticker } from '@typings';

import s from './ModerationStickerOverlay.scss';

interface IModerationStickerOverlayProps {
    sticker: ISticker;
    soundEnabled?: boolean;
    onSoundButtonClick?: () => void;
    onSearchClick?: () => void;
    onMuteClick?: () => void;
}

export const ModerationStickerOverlay = (props: IModerationStickerOverlayProps) => {
    const {
        sticker,
        soundEnabled,
        onSearchClick = noop,
        onMuteClick = noop,
    } = props;

    const onButtonClick = (event: React.MouseEvent<HTMLDivElement>, callback: () => void) => {
        event.stopPropagation();
        event.preventDefault();
        callback();
    };

    return (
        <div className={s.stickerOverlay}>
            <div className={s.stickerOverlayContent}>
                <div className={s.stickerOverlayContentStickerName}>
                    {sticker.name}
                </div>
                <div className={s.stickerOverlayContentSearchIcon} onClick={(e) => onButtonClick(e, onSearchClick)}>
                    <Icon
                        icon={<IconMonitorAltLine/>}
                        size={EComponentSize.SMALL}
                    />
                </div>
                <div className={s.stickerOverlayContentMuteIcon} onClick={(e) => onButtonClick(e, onMuteClick)}>
                    {soundEnabled
                        ? <Icon
                            icon={<IconSoundMuteLine/>}
                            size={EComponentSize.SMALL}
                        />
                        : <Icon
                            icon={<IconSoundActiveLine/>}
                            size={EComponentSize.SMALL}
                        />
                    }
                </div>
            </div>
        </div>
    );
};
