// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Menu__menu--gj6zI {
  display: flex;
}
.Menu__menuHorizontal--ZTBt0 {
  flex-direction: row;
}
.Menu__menuVertical--pWhrV {
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/base/components/menus/Menu/Menu.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AACI;EACI,mBAAA;AACR;AAEI;EACI,sBAAA;AAAR","sourcesContent":[".menu {\n    display: flex;\n\n    &Horizontal {\n        flex-direction: row;\n    }\n\n    &Vertical {\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": `Menu__menu--gj6zI`,
	"menuHorizontal": `Menu__menuHorizontal--ZTBt0`,
	"menuVertical": `Menu__menuVertical--pWhrV`
};
export default ___CSS_LOADER_EXPORT___;
