/** Reflects data structure for an invitation to the MA */
export interface IInvite {
    /** An invitation token */
    invite: string;

    /** BSON id of the issuer (the streamer who issued the invite) */
    issuerId: string;

    /** The flag shows that this invite was activated */
    activated?: boolean;

    /** Timestamp when the invite was activated */
    activatedAt?: number;

    /** BSON id of the user who activated the invite */
    activatedBy?: string;

    /** The flag shows that the invite was generated and added to a streamer manually */
    addedManually?: boolean;
}

/** List of invitation objects */
export type TInvitesList = Array<IInvite>;

export interface IInviteActivationRequest {
    invite: string;
}

export interface IInviteActivationResponse {
    activated: boolean;
    error: boolean;
    errorMessage?: string;
    errorDetails?: string;
}

export interface IInviteActivationStatusResponse {
    status: boolean;
    inviteActivated: boolean;
}
