import {
    Button,
    Column,
    EComponentColor,
    EComponentSize,
    ITextFieldAction,
    Loader,
    NumericField,
    Row,
    SearchField,
} from '@hyperclap/ui';
import cn from 'classnames';
import React, { useState } from 'react';

import { IconCloseLine, IconMagnifierLine } from '@assets/images/svg';
import { TApp } from '@hooks';
import { IViewerWalletItem } from '@typings';
import { Empty } from '@views';

import s from './WalletsTable.scss';

export interface IWalletsTableProps extends TApp {
    wallets?: IViewerWalletItem[];
    isWalletsFetching?: boolean;
    giveBonus: (streamerId: string, value: number) => Promise<void>;
}

enum SearchTextFieldAction {
    RESET = 'reset',
}

export const WalletsTable = (props: IWalletsTableProps) => {
    const {
        translation: {
            t,
        },
        wallets,
        isWalletsFetching = false,
        giveBonus,
    } = props;

    const [loading, setLoading] = useState<boolean>(isWalletsFetching);
    const [query, setQuery] = useState<string>();
    const [streamerId, setStreamerId] = useState<string>();
    const [bonus, setBonus] = useState<number>();

    const onGiveBonusClick = async () => {
        if (streamerId) {
            setLoading(true);
            await giveBonus(streamerId, bonus || 0);
            setBonus(0);
            setLoading(false);
        }
    };

    const searchFieldActions = [
        {
            icon: <IconCloseLine/>,
            name: SearchTextFieldAction.RESET,
            keyboardKeys: ['Escape'],
            hidden: !query,
        },
    ];

    const onSearchTextFieldAction = (action: ITextFieldAction) => {
        if (action.name === SearchTextFieldAction.RESET) {
            setQuery('');
        }
    };

    const rows = wallets?.map((w) => {
        if (query && !w.streamerName.toLowerCase().includes(query.toLowerCase())) return null;

        const isSelectedStreamer = streamerId && streamerId === w.streamerId;
        const giveBonusProcessing = isSelectedStreamer && loading;

        return (
            <>
                <tr key={w.streamerId}
                    className={
                        cn(s.walletsTableBodyRow, {
                            [s.walletsTableBodyRowActive]: isSelectedStreamer,
                        })}
                    onClick={() => setStreamerId(w.streamerId)}
                >
                    <td className={s.walletsTableBodyRowCell}>{w.streamerName}</td>
                    <td
                        className={
                            cn(s.walletsTableBodyRowCell, {
                                [s.walletsTableBodyRowCellLoader]: giveBonusProcessing,
                            })}
                    >
                        {
                            giveBonusProcessing
                                ? <Loader size={EComponentSize.EXTRA_SMALL}/>
                                : w.balance
                        }</td>
                </tr>
                {streamerId && streamerId === w.streamerId &&
                    <tr className={s.bonusControls}>
                        <td>
                            <NumericField
                                placeholder={t('moderator-area.viewers.view.wallets-table.amount')}
                                width={100}
                                defaultValue={bonus}
                                color={EComponentColor.WARNING}
                                onChanged={(value) => setBonus(value)}
                            />
                        </td>
                        <td>
                            <Button
                                caption={t('moderator-area.viewers.view.wallets-table.give-bonus')}
                                style={{ marginLeft: 10 }}
                                disabled={!bonus || bonus == 0 || loading}
                                color={EComponentColor.WARNING}
                                onClick={onGiveBonusClick}
                            />
                        </td>
                    </tr>
                }
            </>
        );
    });

    return (
        <Column grow rowGap={10}>
            <Row className={s.blockRow}>
                <SearchField
                    defaultValue={query}
                    actions={searchFieldActions}
                    placeholder={t('moderator-area.viewers.view.wallets-table.streamer')}
                    tag={<IconMagnifierLine/>}
                    onAction={onSearchTextFieldAction}
                    onChanged={setQuery}
                />
            </Row>
            {rows && !rows.every((x) => !x)
                ? <Row>
                    <table className={s.walletsTable}>
                        <thead>
                            <tr className={s.walletsTableHeadRow}>
                                <td className={s.walletsTableHeadRowCell}>
                                    {t('moderator-area.viewers.view.wallets-table.streamer')}
                                </td>
                                <td className={s.walletsTableHeadRowCell}>
                                    {t('moderator-area.viewers.view.events-table.balance')}
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </Row> : <Empty
                    style={{ marginTop: 20 }}
                    icon={<IconMagnifierLine/>}
                    text={t('moderator-area.viewers.view.wallets-table.empty')}
                />
            }
        </Column>
    );
};
