import { IChannel, IObsOverlayParams, IUser } from '@typings';

import { baseApi } from './base.api';

export const channelApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [
            'Users_CurrentUser',
        ],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            updateChannelData: build.mutation<IUser & { error: string }, Partial<IChannel>>({
                query: (channelData: IChannel) => ({
                    url: 'user/channel',
                    method: 'PUT',
                    body: channelData,
                }),
                invalidatesTags: ['Users_CurrentUser'],
            }),

            updateChannelAvatar: build.mutation<IUser, FormData>({
                query: (imageData: FormData) => ({
                    url: 'user/channel/avatar',
                    method: 'PUT',
                    body: imageData,
                }),
                invalidatesTags: ['Users_CurrentUser'],
            }),

            resetChannelAvatar: build.mutation<IUser, void>({
                query: () => ({
                    url: 'user/channel/avatar',
                    method: 'DELETE',
                }),
                invalidatesTags: ['Users_CurrentUser'],
            }),

            updateChannelMemePartySound: build.mutation<IUser, FormData>({
                query: (soundData: FormData) => ({
                    url: 'user/channel/memeparty-sound',
                    method: 'PUT',
                    body: soundData,
                }),
                invalidatesTags: ['Users_CurrentUser'],
            }),

            clearChannelMemePartySound: build.mutation<IUser, void>({
                query: () => ({
                    url: 'user/channel/memeparty-sound',
                    method: 'DELETE',
                }),
                invalidatesTags: ['Users_CurrentUser'],
            }),

            updateChannelBg: build.mutation<IUser, FormData>({
                query: (imageData: FormData) => ({
                    url: 'user/channel/bg',
                    method: 'PUT',
                    body: imageData,
                }),
                invalidatesTags: ['Users_CurrentUser'],
            }),

            updateChannelCurrencyImage: build.mutation<IUser, FormData>({
                query: (imageData: FormData) => ({
                    url: 'user/channel/currency/image',
                    method: 'PUT',
                    body: imageData,
                }),
                invalidatesTags: ['Users_CurrentUser'],
            }),

            resetChannelCurrencyImage: build.mutation<IUser, void>({
                query: () => ({
                    url: 'user/channel/currency/image',
                    method: 'DELETE',
                }),
                invalidatesTags: ['Users_CurrentUser'],
            }),

            cleanChannelBg: build.mutation<IUser, void>({
                query: () => ({
                    url: 'user/channel/bg-clean',
                    method: 'PUT',
                }),
                invalidatesTags: ['Users_CurrentUser'],
            }),

            obsToggleOverlay: build.mutation<unknown, IObsOverlayParams>({
                query: (params: IObsOverlayParams) => ({
                    url: 'user/channel/obs/toggle-overlay',
                    method: 'POST',
                    body: params,
                }),
            }),

            obsRefetchSettings: build.mutation<unknown, void>({
                query: () => ({
                    url: 'user/channel/obs/refetch-settings',
                    method: 'POST',
                }),
            }),

            setChannelCurrencyName: build.mutation<IUser, string>({
                query: (value: string) => ({
                    url: 'user/channel/currency/name',
                    method: 'POST',
                    body: { value },
                }),
                invalidatesTags: ['Users_CurrentUser'],
            }),

            setChannelSupporterName: build.mutation<IUser, string>({
                query: (value: string) => ({
                    url: 'user/channel/supporter/name',
                    method: 'POST',
                    body: { value },
                }),
                invalidatesTags: ['Users_CurrentUser'],
            }),
        }),
    });
