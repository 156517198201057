import { useEffect, useState } from 'react';

import { api } from '@store';

export interface IStreamerInfoParams {
    query: string;
}

export const useStreamerInfo = (params: IStreamerInfoParams) => {
    const { query } = params;
    const {
        moderation: {
            useLazyGetPrivateUserByNameQuery,
        },
    } = api;

    const [isNotFound, setIsNotFound] = useState(false);

    const [
        findUserByName,
        {
            data: streamer,
            isSuccess,
            isLoading,
            isFetching,
        },
    ] = useLazyGetPrivateUserByNameQuery();

    const findStreamer = () => {
        if (query) {
            findUserByName(query);
            setIsNotFound(false);
        } else {
            setIsNotFound(true);
        }
    };

    useEffect(() => {
        setIsNotFound(false);
        findStreamer();
    }, [query]);

    useEffect(() => {
        if (!streamer) {
            setIsNotFound(true);
        } else {
            setIsNotFound(false);
        }
    }, [streamer]);

    return {
        streamer,
        isSuccess,
        isLoading,
        isFetching,
        isNotFound,

        findStreamer,
    };
};
