import {
    Button,
    Column,
    EColumnAlignment,
    EColumnJustification,
    EComponentColor,
    EComponentSize,
    ERowAlignment,
    Row,
    Switch, useModals,
} from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import cn from 'classnames';
import React, { useEffect, useRef } from 'react';

import {
    IconCheckSquareLine,
    IconCopyrightLine,
    IconDenySquareLine,
    IconEtmaASignSolid,
    IconEtmaESignSolid,
    IconEtmaMSignSolid,
    IconEtmaTSignSolid,
    IconFileAlt,
    IconImageSearch,
    IconKeyholeSquareLine,
    IconMonitorAltLine,
    IconPoopLine,
    IconSendMessageLine,
    IconTagAltLine,
    IconTwitchUnsafeLine,
    IconUserCircleLine,
    IconUserMdLine,
    IconUserSquareLine,
    IconVolumeDownLine,
} from '@assets/images/svg';
import { DEFAULT_SOUND_VOLUME } from '@common';
import { Avatar } from '@components';
import { useAppActions, useAppContext, useAppSelector, useStickerEditor } from '@hooks';
import { ContentMarkAction, EtmaRating, ISticker, ModerationState, SizeCalculationBase } from '@typings';
import { ModerationStickerOverlay, SimilarStickersPopup, Sticker } from '@views';

import s from './StickerView.scss';

const IGDB_THUMB_URL = 'https://images.igdb.com/igdb/image/upload/t_thumb/';

interface StickersListProps {
    sticker: ISticker;
    scrollToView?: boolean;
    onStickerClick?: () => void,
    onEditClick?: () => void,
    onModerationStateChange?: () => void,
}

export const StickerView = (props: StickersListProps) => {
    const {
        sticker,
        scrollToView = false,
        onStickerClick = noop,
        onEditClick = noop,
        onModerationStateChange = noop,
    } = props;

    const app = useAppContext();
    const {
        translation: { t },
        contentMarks: {
            contentMarks,
        },
    } = app;

    const {
        currentSticker,
        name,
        description,
        speech2text,
        ocrText,
        hasCopyright,
        etmaRating,
        isApprovalProhibited,
        isTwitchUnsafe,
        currentContentMarks,
        setHasCopyright,
        setEtmaRating,
        setIsTwitchUnsafe,
        stickerUpdate,
    } = useStickerEditor({
        stickerId: sticker.id,
        stickerToEdit: sticker,
        contentMarks,
        processStickerContentMarks: false,
    });

    const elRef = useRef<HTMLDivElement>(null);

    const { switchPlaySoundOnStickerHover } = useAppActions();
    const playSoundOnStickerHover = useAppSelector((state) => state.app.playSoundOnStickerHover);
    const { showModal } = useModals();

    const onSelectEtma = async (value: EtmaRating) => {
        setEtmaRating(value);
        await stickerUpdate({ id: sticker.id, etmaRating: value });
    };

    const onChangeCopyrightFlag = async (value: boolean) => {
        setHasCopyright(value);
        await stickerUpdate({ id: sticker.id, hasCopyright: value });
    };

    const onChangeTwitchUnsafeFlag = async (value: boolean) => {
        setIsTwitchUnsafe(value);
        await stickerUpdate({ id: sticker.id, isNotTwitchSafe: value });
    };

    const onApproveStickerClick = async () => {
        await stickerUpdate({ id: sticker.id, moderationState: ModerationState.APPROVED });
        onModerationStateChange();
    };

    const onRejectStickerClick = async () => {
        await stickerUpdate({ id: sticker.id, moderationState: ModerationState.DECLINED });
        onModerationStateChange();
    };

    const onSearchClick = async (sticker: ISticker) => {
        showModal({
            content: (
                <SimilarStickersPopup
                    sticker={sticker}
                />
            ),
            options: {},
        });
    };

    const formatDate = (date: Date | number) => {
        const d = typeof date === 'number' ? new Date(date) : date;

        return d.toLocaleTimeString('ru', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        });
    };

    useEffect(() => {
        if (scrollToView) {
            setTimeout(() => {
                elRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
            }, 100);
        }
    }, []);

    return (
        <div ref={elRef}>
            <Row className={s.stickerView} margin={{ bottom: 20 }}>
                <Column grow>
                    <Row className={s.stickerViewStickerName}
                        margin={{ bottom: 15 }}>{name}</Row>
                    <Row grow columnGap={25} alignment={ERowAlignment.TOP}>
                        <Column>
                            {currentSticker &&
                                <Sticker
                                    className={s.stickerViewSticker}
                                    sticker={currentSticker}
                                    withSound={playSoundOnStickerHover}
                                    soundOnHover
                                    loop
                                    useFadeInOnLoaded
                                    useSkeletonOnLoading
                                    useDefaultOverlays={false}
                                    volume={DEFAULT_SOUND_VOLUME}
                                    sizeCalculationBase={SizeCalculationBase.WIDTH}
                                    onClick={onStickerClick}
                                    overlay={
                                        <ModerationStickerOverlay
                                            sticker={currentSticker}
                                            soundEnabled={playSoundOnStickerHover}
                                            onMuteClick={switchPlaySoundOnStickerHover}
                                            onSearchClick={() => onSearchClick(currentSticker)}
                                        />
                                    }
                                />
                            }
                        </Column>
                        <Column
                            rowGap={15}
                            className={s.stickerViewStickerTextColumn}
                            justification={EColumnJustification.TOP}
                        >
                            <Row columnGap={5}>
                                <Column className={
                                    cn(s.stickerViewStickerTextColumnIcon, {
                                        [s.stickerViewStickerTextColumnIconDisabled]: !description,
                                    })}>
                                    <IconFileAlt/>
                                </Column>
                                <Column grow className={
                                    cn(s.stickerViewStickerTextColumnText, {
                                        [s.stickerViewStickerTextColumnTextDisabled]: !description,
                                    })}>
                                    {description || t('moderator-area.stickers-list.no-description')}
                                </Column>
                            </Row>
                            <Row columnGap={5}>
                                <Column className={
                                    cn(s.stickerViewStickerTextColumnIcon, {
                                        [s.stickerViewStickerTextColumnIconDisabled]: !speech2text,
                                    })}>
                                    <IconVolumeDownLine/>
                                </Column>
                                <Column grow className={
                                    cn(s.stickerViewStickerTextColumnText, {
                                        [s.stickerViewStickerTextColumnTextDisabled]: !speech2text,
                                    })}>
                                    {speech2text || t('moderator-area.stickers-list.no-speech2text')}
                                </Column>
                            </Row>
                            <Row columnGap={5}>
                                <Column className={
                                    cn(s.stickerViewStickerTextColumnIcon, {
                                        [s.stickerViewStickerTextColumnIconDisabled]: !ocrText,
                                    })}>
                                    <IconImageSearch/>
                                </Column>
                                <Column grow className={
                                    cn(s.stickerViewStickerTextColumnText, {
                                        [s.stickerViewStickerTextColumnTextDisabled]: !ocrText,
                                    })}>
                                    {ocrText || t('moderator-area.stickers-list.no-ocrText')}
                                </Column>
                            </Row>
                            <Row columnGap={5}>
                                <Column className={
                                    cn(s.stickerViewStickerTextColumnIcon, {
                                        [s.stickerViewStickerTextColumnIconDisabled]: !currentSticker?.tags?.length,
                                    })}>
                                    <IconTagAltLine/>
                                </Column>
                                <Column grow className={
                                    cn(s.stickerViewStickerTextColumnText, {
                                        [s.stickerViewStickerTextColumnTextDisabled]: !currentSticker?.tags?.length,
                                    })}>
                                    {
                                        currentSticker?.tags?.map((t) => t.name).join(', ') ||
                                        t('moderator-area.stickers-list.no-tags')
                                    }
                                </Column>
                            </Row>
                            <Row columnGap={5}>
                                <Column width={20}>
                                    {currentSticker?.streamerName
                                        ? <Avatar
                                            className={s.gameItemIconAvatar}
                                            source={sticker.streamerAvatar}
                                            size={EComponentSize.EXTRA_SMALL}
                                            rounded
                                        />
                                        : <IconUserCircleLine
                                            className={s.stickerViewStickerTextColumnIconDisabled}
                                        />
                                    }
                                </Column>
                                <Column grow className={
                                    cn(s.stickerViewStickerTextColumnText, {
                                        [s.stickerViewStickerTextColumnTextDisabled]: !currentSticker?.streamerName,
                                    })}>
                                    {currentSticker?.streamerName || t('moderator-area.stickers-list.no-streamer')}
                                </Column>
                            </Row>
                            <Row columnGap={5}>
                                <Column width={20}>
                                    {sticker.gameName
                                        ? <Avatar
                                            className={s.gameItemIconAvatar}
                                            source=
                                                {`${IGDB_THUMB_URL}${sticker.gameCoverThumbId}.jpg`}
                                            size={EComponentSize.EXTRA_SMALL}
                                            rounded
                                        />
                                        : <IconMonitorAltLine
                                            className={s.stickerViewStickerTextColumnIconDisabled}
                                        />
                                    }
                                </Column>
                                <Column grow className={
                                    cn(s.stickerViewStickerTextColumnText, {
                                        [s.stickerViewStickerTextColumnTextDisabled]: !currentSticker?.gameName,
                                    })}>
                                    {currentSticker?.gameName || t('moderator-area.stickers-list.no-game')}
                                </Column>
                            </Row>
                        </Column>
                        <Column grow className={s.stickerViewStickerActionsColumn} rowGap={10}>
                            <Row grow>
                                <div className={s.stickerViewStickerActionsColumnETMA}>
                                    <div onClick={() => onSelectEtma(EtmaRating.E)}
                                        className={cn(s.icon, {
                                            [s.iconActiveE]: etmaRating === EtmaRating.E,
                                        })}><IconEtmaESignSolid/></div>
                                    <div onClick={() => onSelectEtma(EtmaRating.T)}
                                        className={cn(s.icon, {
                                            [s.iconActiveT]: etmaRating === EtmaRating.T,
                                        })}><IconEtmaTSignSolid/></div>
                                    <div onClick={() => onSelectEtma(EtmaRating.M)}
                                        className={cn(s.icon, {
                                            [s.iconActiveM]: etmaRating === EtmaRating.M,
                                        })}><IconEtmaMSignSolid/></div>
                                    <div onClick={() => onSelectEtma(EtmaRating.A)}
                                        className={cn(s.icon, {
                                            [s.iconActiveA]: etmaRating === EtmaRating.A,
                                        })}><IconEtmaASignSolid/></div>
                                </div>
                            </Row>
                            <Row>
                                <div className={s.stickerViewStickerMarks}>
                                    {currentContentMarks.map((mark) => (
                                        <div
                                            className={cn(s.item, {
                                                [s.red]: mark.actions?.includes(ContentMarkAction.REJECT),
                                                [s.twitch]: mark.actions?.includes(ContentMarkAction.TWITCH_UNSAFE),
                                            })} key={mark.id}>
                                            <div className={s.itemText}>{mark.name}</div>
                                        </div>
                                    ))}
                                </div>
                            </Row>
                            <Row grow columnGap={10}>
                                <Column rowGap={10} className={s.stickerViewStickerActionsColumnIcons}>
                                    <Row className={cn(s.icon, { [s.iconDanger]: isTwitchUnsafe })}>
                                        <IconTwitchUnsafeLine/>
                                    </Row>
                                    <Row className={cn(s.icon, { [s.iconDanger]: hasCopyright })}>
                                        <IconCopyrightLine/>
                                    </Row>
                                </Column>
                                <Column rowGap={10} className={s.stickerViewStickerActionsColumnText}>
                                    <Row
                                        className={cn(s.title, { [s.titleDanger]: isTwitchUnsafe })}>
                                        Twitch Unsafe
                                    </Row>
                                    <Row className={cn(s.title, { [s.titleDanger]: hasCopyright })}>
                                        Copyright
                                    </Row>
                                </Column>
                                <Column grow rowGap={10}>
                                    <Row>
                                        <Switch
                                            checked={isTwitchUnsafe}
                                            onChange={onChangeTwitchUnsafeFlag}
                                            color={EComponentColor.TWITCH}
                                        />
                                    </Row>
                                    <Row>
                                        <Switch
                                            checked={hasCopyright}
                                            onChange={onChangeCopyrightFlag}
                                            color={EComponentColor.WARNING}
                                        />
                                    </Row>
                                </Column>
                            </Row>
                            <Row grow>
                                <Column rowGap={10}>
                                    <Row columnGap={10}>
                                        <Column rowGap={10}
                                            className={s.stickerViewStickerActionsColumnIcons}>
                                            <Row className={s.icon}>
                                                <IconSendMessageLine/>
                                            </Row>
                                        </Column>
                                        <Column rowGap={10}
                                            style={{ minWidth: 50 }}
                                            className={s.stickerViewStickerActionsColumnText}>
                                            <Row className={s.title}>
                                                {currentSticker?.usageCount}
                                            </Row>
                                        </Column>
                                    </Row>
                                </Column>
                                <Column>
                                    <Row columnGap={10}>
                                        <Column rowGap={10}
                                            className={s.stickerViewStickerActionsColumnIcons}>
                                            <Row className={cn(s.icon, {
                                                [s.iconDanger]: currentSticker?.disableCount && currentSticker.disableCount > 0,
                                            })
                                            }>
                                                <IconPoopLine/>
                                            </Row>
                                        </Column>
                                        <Column rowGap={10}
                                            className={s.stickerViewStickerActionsColumnText}>
                                            <Row className={cn(s.title, {
                                                [s.titleDanger]: currentSticker?.disableCount && currentSticker.disableCount > 0,
                                            })
                                            }>
                                                {currentSticker?.disableCount ?? 0}
                                            </Row>
                                        </Column>
                                    </Row>
                                </Column>
                            </Row>
                            <Row grow columnGap={10} alignment={ERowAlignment.TOP}>
                                <Column rowGap={10} className={s.stickerViewStickerActionsColumnIcons}>
                                    <Row className={cn(s.icon, s.iconGood, {
                                        [s.iconDanger]: !currentSticker?.creatorTotalApprovedPercent ||
                                        currentSticker.creatorTotalApprovedPercent < 70,
                                    })}>
                                        <IconUserSquareLine/>
                                    </Row>
                                </Column>
                                <Column className={s.stickerViewStickerActionsColumnText}>
                                    <Row className={cn(s.title, s.titleGood, {
                                        [s.titleDanger]: !currentSticker?.creatorTotalApprovedPercent ||
                                        currentSticker.creatorTotalApprovedPercent < 70,
                                    })
                                    }>
                                        {currentSticker?.creatorName}
                                    </Row>
                                    <Row className={cn(s.subtitle, s.subtitleGood, {
                                        [s.subtitleDanger]: !currentSticker?.creatorTotalApprovedPercent ||
                                        currentSticker.creatorTotalApprovedPercent < 70,
                                    })
                                    }>
                                        {Math.round(currentSticker?.creatorTotalApprovedPercent ?? 0)} %
                                        ({currentSticker?.creatorTotalApproved})
                                        <span
                                            className={s.date}> • {formatDate(currentSticker?.createdAt ?? 0)}</span>
                                    </Row>
                                </Column>
                            </Row>
                            <Row grow columnGap={10} alignment={ERowAlignment.TOP}>
                                <Column rowGap={10} className={s.stickerViewStickerActionsColumnIcons}>
                                    <Row className={cn(s.icon, s.iconGood, {
                                        [s.iconDanger]: currentSticker?.moderationState === ModerationState.DECLINED,
                                        [s.iconWarning]: currentSticker?.moderationState === ModerationState.POST_MODERATION ||
                                        currentSticker?.moderationState === ModerationState.MODERATION,
                                    })}>
                                        {currentSticker?.moderationState === ModerationState.DECLINED &&
                                            <IconDenySquareLine/>
                                        }
                                        {(currentSticker?.moderationState === ModerationState.MODERATION ||
                                                currentSticker?.moderationState === ModerationState.POST_MODERATION) &&
                                            <IconKeyholeSquareLine/>
                                        }
                                        {currentSticker?.moderationState === ModerationState.APPROVED &&
                                            <IconCheckSquareLine/>
                                        }
                                    </Row>
                                </Column>
                                <Column className={s.stickerViewStickerActionsColumnText}>
                                    <Row className={cn(s.title, s.titleGood, {
                                        [s.titleDanger]: currentSticker?.moderationState === ModerationState.DECLINED,
                                        [s.titleWarning]: currentSticker?.moderationState === ModerationState.POST_MODERATION ||
                                        currentSticker?.moderationState === ModerationState.MODERATION,
                                    })
                                    }>
                                        {t(`moderator-area.moderation-state.state-${
                                            currentSticker?.moderationState.toLowerCase()
                                        }`)}
                                    </Row>
                                </Column>
                            </Row>
                            <Row grow columnGap={10} alignment={ERowAlignment.TOP}>
                                <Column rowGap={10} className={s.stickerViewStickerActionsColumnIcons}>
                                    <Row className={s.icon}>
                                        <IconUserMdLine/>
                                    </Row>
                                </Column>
                                <Column className={s.stickerViewStickerActionsColumnText}>
                                    <Row className={s.title}>
                                        {currentSticker?.moderatorName ? currentSticker.moderatorName : '-'}
                                    </Row>
                                    {currentSticker?.moderatedAt &&
                                        <Row className={s.subtitle}>
                                            {formatDate(currentSticker.moderatedAt ?? 0)}
                                        </Row>
                                    }
                                </Column>
                            </Row>
                        </Column>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Column>
                            {currentSticker?.moderationState !== ModerationState.DECLINED &&
                                <Button
                                    height={35}
                                    color={EComponentColor.DANGER}
                                    caption={t('moderator-area.common.reject')}
                                    onClick={onRejectStickerClick}
                                />
                            }
                        </Column>
                        <Column grow alignment={EColumnAlignment.END}>
                            <Row columnGap={25}>
                                <Column>
                                    {currentSticker?.moderationState !== ModerationState.APPROVED && !isApprovalProhibited &&
                                        <Button
                                            height={35}
                                            color={EComponentColor.SUCCESS}
                                            caption={t('moderator-area.common.approve')}
                                            onClick={onApproveStickerClick}
                                        />
                                    }
                                </Column>
                                <Column>
                                    <Button
                                        height={35}
                                        color={EComponentColor.WARNING}
                                        caption={t('moderator-area.common.edit')}
                                        onClick={() => {
                                            onEditClick();
                                        }}
                                    />
                                </Column>
                            </Row>
                        </Column>
                    </Row>
                </Column>
            </Row>
        </div>
    );
};
