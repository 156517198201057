import { useEffect, useState } from 'react';

import { useApi } from '@hooks';
import { IContentMark, IContentMarksByCategory, IUser } from '@typings';

export interface IContentMarkParams {
    currentUser?: IUser;
}

export const useContentMarks = (params: IContentMarkParams) => {
    const {
        currentUser,
    } = params;

    const {
        moderation: {
            useLazyLoadContentMarksQuery,
        },
    } = useApi();

    const [contentMarks, setContentMarks] = useState<Array<IContentMark>>();
    const [contentMarksByCategory, setContentMarksByCategory] = useState<IContentMarksByCategory>();

    const [
        load,
        {
            isFetching: isContentMarksFetching,
        },
    ] = useLazyLoadContentMarksQuery();

    const loadContentMarks = async () => {
        const loadedMarks = await load().unwrap();

        if (loadedMarks) {
            setContentMarks(loadedMarks);

            const grouped = loadedMarks.reduce((result: IContentMarksByCategory, currentValue) => {
                (result[currentValue.category] = result[currentValue.category] || []).push(currentValue);

                return result;
            }, {});
            setContentMarksByCategory(grouped);
        }
    };

    useEffect(() => {
        if (currentUser) {
            void loadContentMarks();
        }
    }, [currentUser]);

    return {
        contentMarks,
        contentMarksByCategory,
        isContentMarksFetching,
    };
};
