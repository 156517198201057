import { ELogLevel } from '@hyperclap/ui';

/** Hosts URLs are needed for the client application */
interface IApplicationHostsConfig {
    /** Main application host URL */
    appHost: string;

    /** API host URL */
    apiHost: string;

    /** Websocket host URL */
    wsHost: string;

    /** Meme Creator API host */
    mcApiHost: string;

    /** Streamoji API host */
    streamojiApiHost: string;
}

interface ILoggingConfig {
    level: ELogLevel
}

interface IDevelopmentModeConfig {
    disableStrictMode?: boolean;
}

interface IResponsivePageConfig {
    contentWidth: number;
    sidebarWidth: number;
}

interface ILayoutConfig {
    desktopLayoutMinWidth: number;
    responsivePage: IResponsivePageConfig;
}

interface IObsConfig {
    minStickerPlayingTimeSec: 5,
    stickerSize: {
        [index: string]: number;
    },
}

interface IAdditionalConfig {
    telegramBotName: string;
}

/** Configuration object structure */
export interface IConfig {
    developmentMode?: IDevelopmentModeConfig;
    hosts: IApplicationHostsConfig;
    layout: ILayoutConfig;
    logging: ILoggingConfig;
    obs: IObsConfig;
    additional: IAdditionalConfig;
}
