import { useClickOutside } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { IconGlobeLine } from '@assets/images/svg';
import { ILanguage } from '@typings';

import s from './LanguageSelector.scss';


interface LanguageSelectorProps {
    className?: string;
    language: ILanguage;
    languages: Array<ILanguage>;
    onChange?: (lang: ILanguage) => void;
}

export const LanguageSelector = (props: LanguageSelectorProps) => {
    const { className, language, languages, onChange = noop } = props;

    const [isMounted, setIsMounted] = useState(false);
    const [userLanguage, setUserLanguage] = useState(language);
    const [isDropDownVisible, setIsDropDownVisible] = useState(false);

    const menuRef = useRef<HTMLDivElement>(null);

    useClickOutside({ controlledElementRef: menuRef, handler: () => setIsDropDownVisible(false) });

    useEffect(() => {
        setIsMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted) {
            onChange(userLanguage);
        }
    }, [userLanguage]);

    return (
        <div className={cn(s.languageSelector, className)} ref={menuRef} onClick={() => setIsDropDownVisible((prev) => !prev)}>
            <div className={s.icon}>
                <IconGlobeLine />
            </div>
            <div className={s.language}>
                {userLanguage.name}
            </div>
            <div className={s.dropdownBtn}>
                <div/>
            </div>

            {isDropDownVisible &&
                <ul className={s.dropdownMenu}>
                    { languages.map((l) => (
                        <li key={l.shortName} onClick={() => setUserLanguage(l)}>{l.name}</li>
                    ))}
                </ul>
            }
        </div>
    );
};
