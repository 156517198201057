import { Column } from '@hyperclap/ui';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppContext } from '@hooks';
import { useNavigator } from '@hooks/app/navigator';

export const Viewers = () => {
    const app = useAppContext();
    const { goToModerationRoom } = useNavigator();

    const {
        currentUser: {
            currentUser,
        },
        menu: {
            leftSideMenu: {
                setHiddenLeftSideBar,
            },
        },
    } = app;

    useEffect(() => {
        if (currentUser && !currentUser.flags?.isAdmin && !currentUser.flags?.isMasterModer) {
            goToModerationRoom();
        }
    }, [currentUser]);

    useEffect(() => {
        setHiddenLeftSideBar(false);
    }, []);

    return (
        <Column grow>
            <Outlet context={{ ...app }}/>
        </Column>
    );
};
