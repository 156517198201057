import React, { CSSProperties } from 'react';

import { ImageDancingDog } from '@assets/images/gif';

import s from './DogLoader.scss';

interface IDogLoaderProps {
    size?: number
}

export const DogLoader = ({ size = 50 }: IDogLoaderProps) => {
    const styles: CSSProperties = {
        maxHeight: size,
        maxWidth: size,
        minHeight: size,
        minWidth: size,
    };

    return (
        <div className={s.dogLoader} style={styles}>
            <img src={ImageDancingDog} alt={'In progress'} title={'Loader'} style={styles} />
        </div>
    );
};
