import { ThemeContext } from '@hyperclap/ui';
import React, { useContext, useLayoutEffect } from 'react';

import { TApp } from '@hooks';
import { Footer } from '@memealerts/views';
import { DesktopPage } from '@views';

import { LeftSideMenu, Header } from './components';


interface IStreamerSettingsPage extends TApp {
    noRightSidebar?: boolean;
}

export const ModeratorAreaPage = (props: React.PropsWithChildren<IStreamerSettingsPage>) => {
    const { forceUpdateTheme } = useContext(ThemeContext);

    const {
        children,
        noRightSidebar,
        menu: {
            leftSideMenu: {
                hideLeftSideBar,
            },
        },
    } = props;

    useLayoutEffect(() => {
        forceUpdateTheme();
    }, []);

    const header = <Header {...props}/>;
    const footer = <Footer {...props}/>;
    const leftMenu = <LeftSideMenu {...props}/>;

    return (
        <DesktopPage header={header} footer={footer}
            hideLeftSidebar={hideLeftSideBar} leftSidebar={leftMenu}
            hideRightSidebar={noRightSidebar} stickyHeader>
            {children}
        </DesktopPage>
    );
};
