export interface IDailyData {
    date: number;
}

export type TDailyData<T> = IDailyData & {
    [P in keyof T]: T[P];
}

export interface IEarningsData {
    earnings: number;
    supportersCount: number;
}

export interface IPurchasesData {
    purchasedValue: number;
    bonusesValue: number;
}

export interface IStickersData {
    spent: number;
    stickers: number;
    memeCannon: number;
    fullscreen: number;
}

export type TDashboardDailyData = TDailyData<IEarningsData & IPurchasesData & IStickersData>;

export interface IDashboardPeriodData {
    daysInfo: Array<TDashboardDailyData>;
    periodTotal: IEarningsData;
    // TODO: Make JSON format as pointed below
    // data: Array<TDashboardDailyData>;
    // total: IEarningsData;
}
