import { Button, ERowJustification, Loader, Row, useModals } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import React, { useEffect, useRef, useState } from 'react';

import { IconImageSearch } from '@assets/images/svg';
import { StickersListMode, useAppContext, useElementOnScreen, useStickersList } from '@hooks';
import { useNavigator } from '@hooks/app/navigator';
import { IContentMark, ISticker } from '@typings';
import { Empty, MassTagPopup, StickerPlayer } from '@views';
import { StickerView } from '@views/fragments/StickersList/components';

import s from './StickersList.scss';

interface StickersListProps {
    searchQuery: string;
    stickersListMode: StickersListMode,
    scrollIndex?: number;
    filters?: IContentMark[];
    setStickerToEdit?: (sticker: ISticker) => void;
}

export const StickersList = (props: StickersListProps) => {
    const {
        searchQuery,
        stickersListMode,
        scrollIndex,
        filters,
        setStickerToEdit = noop,
    } = props;

    const app = useAppContext();
    const {
        translation: { t },
        stickersCreation: {
            playingStickersList: {
                list,
                addToList,
                removeFromList,
            },
        },
        menu: {
            leftSideMenu: {
                setHiddenLeftSideBar,
            },
        },
    } = app;

    const {
        stickers,
        isStickersFetching,
        loadNextPage,
        carefullyReloadStickers,
        massUpdateStickers,
    } = useStickersList({
        filters,
        searchQuery,
        stickersListMode,
        firstPageSize: scrollIndex,
    });

    const {
        goToModerationStickersTab,
        goToStickersEditor,
    } = useNavigator();

    const { showModal } = useModals();

    const stickersRef = useRef<ISticker[]>([]);
    const loadNextPageRef = useRef<() => Promise<void>>();

    const [isEndReached, setIsEndReached] = useState(false);

    const {
        elementRef: watchdogRef,
        isOnScreen: needToLoadPage,
    } = useElementOnScreen<HTMLDivElement>({ threshold: 0 });

    const onStickerClick = (sticker: ISticker) => {
        addToList(sticker);
    };

    const onEditStickerClick = (sticker: ISticker, index: number) => {
        setStickerToEdit(sticker);
        goToModerationStickersTab(stickersListMode, searchQuery, index.toString());
        goToStickersEditor(sticker.id);
    };

    const playingStickers = list.map((sticker) => (
        <StickerPlayer
            key={sticker.uid}
            sticker={sticker.sticker}
            geometry={sticker.geometry}
            onPlayEnd={() => removeFromList(sticker.uid)}
        />
    ));

    const onMassEditClick = () => {
        if (stickersListMode === StickersListMode.APPROVED) {
            showModal({
                content: (
                    <MassTagPopup
                        filter={searchQuery}
                        massUpdateStickers={massUpdateStickers}
                    />
                ),
                options: {},
            });
        }
    };

    useEffect(() => {
        if (needToLoadPage) {
            void loadNextPage();
        }
    }, [needToLoadPage]);

    useEffect(() => {
        setIsEndReached(false);
    }, [stickersListMode]);

    useEffect(() => {
        stickersRef.current = stickers;
    }, [stickers]);

    useEffect(() => {
        loadNextPageRef.current = loadNextPage;
    }, [loadNextPage]);

    useEffect(() => {
        setHiddenLeftSideBar(false);
    }, []);

    return (
        <>
            {stickers.length > 0
                ? <>
                    <Row style={{ marginBottom: 10 }} justification={ERowJustification.END}>
                        {stickersListMode === StickersListMode.APPROVED && searchQuery.length > 0 &&
                            <Button
                                height={35}
                                caption={t('moderator-area.stickers-list.mass-edit')}
                                onClick={onMassEditClick}
                            />
                        }
                    </Row>
                    {stickers.map((sticker, index ) => (
                        <div key={sticker.id}>
                            {(index === ((stickers.length - 3) > 0 ? (stickers.length - 3)
                                : (stickers.length - 1)) && !isStickersFetching && !isEndReached) && (
                                <div className={s.nextPageWatchdog}>
                                    <div className={s.nextPageWatchdogMarker} ref={watchdogRef}/>
                                </div>
                            )}
                            <StickerView
                                sticker={sticker}
                                scrollToView={scrollIndex ? index === scrollIndex : false}
                                onStickerClick={() => onStickerClick(sticker)}
                                onEditClick={() => onEditStickerClick(sticker, index)}
                                onModerationStateChange={carefullyReloadStickers}
                            />
                        </div>
                    ))}
                    {isStickersFetching &&
                        <Loader/>
                    }
                </>
                : isStickersFetching
                    ? <Loader margin={{ top: 70 }}/>
                    : <Empty
                        padding={{ top: 70 }}
                        icon={<IconImageSearch/>}
                        iconSize={50}
                        text={t('moderator-area.stickers-list.no-stickers')}
                    />
            }

            <div className={s.stickersShadow}>
                {playingStickers}
            </div>
        </>
    );
};
