import React, { createContext, useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';

import { NotificationArea } from './NotificationArea';

export enum NotificationType {
    ERROR = 'error',
    WARN = 'warn',
    SUCCESS = 'success',
    INFO = 'info'
}

export interface NotificationData {
    uid: string;
    text: string;
    type?: NotificationType;
    timeout?: number;
}

interface NotificationContextType {
    notifications: Array<NotificationData>;
    notify: (text: string, type: NotificationType, timeout?: number) => void;
    close: (uid: string) => void;
}

export const NotificationContext = createContext<NotificationContextType>(undefined!);

export const NotificationProvider = (props: React.PropsWithChildren) => {
    const { children } = props;

    const [notifications, setNotifications] = useState<Array<NotificationData>>([]);
    const notificationsRef = useRef(notifications);

    const notify = (text: string, type: NotificationType, timeout?: number) => {
        const uid = v4();
        setNotifications(notificationsRef.current.concat({ uid, text, type, timeout }));
        setTimeout(() => {
            setNotifications(notificationsRef.current.filter((n) => n.uid !== uid));
        }, timeout ?? 3000);
    };

    const close = (uid: string) => {
        setNotifications(notificationsRef.current.filter((n) => n.uid !== uid));
    };

    useEffect(() => {
        notificationsRef.current = notifications;
    }, [notifications]);

    const value: NotificationContextType = {
        notifications,
        notify,
        close,
    };

    return (
        <NotificationContext.Provider value={value}>
            {children}
            <NotificationArea />
        </NotificationContext.Provider>
    );
};
