import { Button } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconGiftLine } from '@assets/images/svg';
import { HeaderButton } from '@views';

import s from './EarnBonusButton.scss';

interface EarnBonusButtonProps {
    noShadow?: boolean;
    onClick?: () => void;
}

export const EarnBonusButton = (props: EarnBonusButtonProps) => {
    const { t } = useTranslation();
    const {
        noShadow,
        onClick = noop,
    } = props;

    return noShadow
        ? <Button
            className={s.earnBonusButton}
            caption={t('streamer-area.header.getBonus')}
            icon={<IconGiftLine/>}
            onClick={onClick}
        />
        : <HeaderButton
            className={s.earnBonusButton}
            caption={t('streamer-area.header.getBonus')}
            icon={<IconGiftLine/>}
            onClick={onClick}
        />;
};
