import { Column, Row } from '@hyperclap/ui';
import React, { useEffect, useState } from 'react';

import { useAppContext } from '@hooks';
import { useNavigator } from '@hooks/app/navigator';
import { BadWordsEditor, ITabParams, Tabs } from '@views';

enum DictionaryType {
    DEFAULT = 'default',
    TWITCH = 'twitch',
    MILD = 'mild_swear',
    MEDIUM = 'medium_swear',
    STRONG = 'strong_swear',
    HATE = 'hate_speech',
}

export const Filters = () => {
    const app = useAppContext();
    const { goToModerationRoom } = useNavigator();

    const {
        translation: {
            t,
        },
        currentUser: {
            currentUser,
        },
        menu: {
            leftSideMenu: {
                setHiddenLeftSideBar,
            },
        },
    } = app;

    const [dictionaryType, setDictionaryType] = useState<DictionaryType>(DictionaryType.DEFAULT);

    const TABS: Array<ITabParams<DictionaryType>> = [
        { caption: t('moderator-area.filters.default'), tag: DictionaryType.DEFAULT },
        { caption: t('moderator-area.filters.twitch'), tag: DictionaryType.TWITCH },
        { caption: t('moderator-area.filters.mild'), tag: DictionaryType.MILD },
        { caption: t('moderator-area.filters.medium'), tag: DictionaryType.MEDIUM },
        { caption: t('moderator-area.filters.strong'), tag: DictionaryType.STRONG },
        { caption: t('moderator-area.filters.hate'), tag: DictionaryType.HATE },
    ];

    const onTabSelected = (type: DictionaryType = DictionaryType.DEFAULT) => {
        setDictionaryType(type);
    };

    useEffect(() => {
        if (currentUser && !currentUser.flags?.isAdmin && !currentUser.flags?.isMasterModer) {
            goToModerationRoom();
        }
    }, [currentUser]);

    useEffect(() => {
        setHiddenLeftSideBar(false);
    }, []);

    return (
        <Column>
            <Row style={{ marginTop: 15 }}>
                <Tabs activeTab={dictionaryType} onSelectionChange={onTabSelected} tabs={TABS}/>
            </Row>
            <Row>
                <BadWordsEditor dictionaryName={dictionaryType}/>
            </Row>
        </Column>
    );
};
