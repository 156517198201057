export interface IMorpherDeclensions {
    genitive: string;
    dative: string;
    accusative: string;
    instrumental: string;
    prepositional: string;
    multiple : {
        nominative: string;
        genitive: string;
        dative: string;
        accusative: string;
        instrumental: string;
        prepositional: string;
    };
    successProcessed: boolean;
}
