import { noop } from '@hyperclap/utils';
import { useEffect, useRef, useState } from 'react';

import { useApi } from '@hooks';
import { IContentMark, IMassStickersUpdate, ISticker } from '@typings';

export interface IStickersListParams {
    searchQuery: string;
    filters?: IContentMark[];
    stickersListMode?: StickersListMode;
    firstPageSize?: number;
}

const PAGE_SIZE = 20;

export enum StickersListMode {
    APPROVED = 'APPROVED',
    BLACKLIST = 'BLACKLIST',
    BLACKLIST_DATE = 'BLACKLIST_DATE',
    MODERATION = 'MODERATION',
    REJECTED = 'REJECTED',
}

export const useStickersList = (params: IStickersListParams) => {
    const {
        searchQuery,
        filters,
        stickersListMode = StickersListMode.MODERATION,
        firstPageSize = 0,
    } = params;

    const {
        moderation: {
            useLazyLoadStickersPageQuery,
            useMassUpdateStickersMutation,
        },
    } = useApi();

    const [
        loadPage,
        {
            isFetching: isStickersFetching,
        },
    ] = useLazyLoadStickersPageQuery();

    const [
        massUpdate,
    ] = useMassUpdateStickersMutation();

    const abortFn = useRef<() => void>(noop);

    const [stickers, setStickers] = useState<Array<ISticker>>([]);
    const [isEndReached, setIsEndReached] = useState(false);
    const [isPageLoadingAllowed, setIsPageLoadingAllowed] = useState(false);

    const loadNextPage = async () => {
        if (!isEndReached && !isStickersFetching) {
            const params = {
                limit: PAGE_SIZE,
                skip: stickers.length,
                filters: filters?.map((x) => x.id),
                searchQuery,
                stickersListMode,
            };

            if (isStickersFetching && abortFn.current !== noop) {
                abortFn.current();
                abortFn.current = noop;
            }

            const { abort, unwrap } = loadPage(params, false);

            abortFn.current = abort;

            const page = await unwrap();

            setStickers(stickers.concat(page));

            setIsEndReached(!page?.length || page?.length < PAGE_SIZE);

            abortFn.current = noop;
        }
    };

    const updateLoadedSticker = (sticker: ISticker) => {
        setStickers(stickers.map((s) => s.id === sticker.id ? sticker : s));
    };

    const reloadStickers = async () => {
        setStickers([]);

        const pageSize = firstPageSize + PAGE_SIZE;

        const params = {
            limit: pageSize,
            skip: 0,
            filters: filters?.map((x) => x.id),
            searchQuery,
            stickersListMode,
        };

        if (isStickersFetching && abortFn.current !== noop) {
            abortFn.current();
            abortFn.current = noop;
        }

        const { abort, unwrap } = loadPage(params, false);

        abortFn.current = abort;

        try {
            const page = await unwrap();

            setStickers(page);

            setIsEndReached(!page?.length || page?.length < pageSize);

            abortFn.current = noop;
        } catch (error) {/* empty */}
    };

    const carefullyReloadStickers = async () => {
        const params = {
            limit: stickers.length,
            skip: 0,
            filters: filters?.map((x) => x.id),
            searchQuery,
            stickersListMode,
        };

        if (isStickersFetching && abortFn.current !== noop) {
            abortFn.current();
            abortFn.current = noop;
        }

        const { abort, unwrap } = loadPage(params, false);

        abortFn.current = abort;

        try {
            const page = await unwrap();

            setStickers(page);

            setIsEndReached(!page?.length || page?.length < stickers.length);

            abortFn.current = noop;
        } catch (error) {/* empty */}
    };

    const massUpdateStickers = async (updateData: IMassStickersUpdate): Promise<number> => {
        const updatedCount = await massUpdate({ ...updateData }).unwrap();

        if (updatedCount > 0) {
            await carefullyReloadStickers();
        }

        return updatedCount;
    };

    useEffect(() => {
        void reloadStickers();
    }, [searchQuery, stickersListMode, filters]);

    useEffect(() => {
        setIsPageLoadingAllowed(!isStickersFetching && !isEndReached);
    }, [isEndReached, isStickersFetching]);

    return {
        stickers,
        isStickersFetching,
        isPageLoadingAllowed,

        reloadStickers,
        carefullyReloadStickers,
        updateLoadedSticker,
        massUpdateStickers,
        loadNextPage,
    };
};
