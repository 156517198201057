import { Column, Row } from '@hyperclap/ui';
import cn from 'classnames';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';

import s from './Grid.scss';

const GridAreasMask = {
    TOP_LEFT: 1,
    TOP: 2,
    TOP_RIGHT: 4,
    LEFT: 8,
    CENTER: 16,
    RIGHT: 32,
    BOTTOM_LEFT: 64,
    BOTTOM: 128,
    BOTTOM_RIGHT: 256,
};

interface GridProps {
    className?: string;
    mask: number;
    multiselect: boolean;
    skipSecondRow?: boolean;
    onSelectionChange?: (selectionMask: number) => void;
}

export const Grid = (props: GridProps) => {
    const GridAreas = {
        TOP_LEFT: t('streamer-settings.stickers.top-left'),
        TOP: t('streamer-settings.stickers.top'),
        TOP_RIGHT: t('streamer-settings.stickers.top-right'),
        LEFT: t('streamer-settings.stickers.left'),
        CENTER: t('streamer-settings.stickers.center'),
        RIGHT: t('streamer-settings.stickers.right'),
        BOTTOM_LEFT: t('streamer-settings.stickers.bottom-left'),
        BOTTOM: t('streamer-settings.stickers.bottom'),
        BOTTOM_RIGHT: t('streamer-settings.stickers.bottom-right'),
    };

    const { mask, multiselect, skipSecondRow, onSelectionChange } = props;

    const [selectionMask, setSelectionMask] = useState(mask);

    const onAreaClick = (areaMask: number) => {
        const selected = multiselect ? (selectionMask ^ areaMask) : areaMask;
        setSelectionMask(selected);
        onSelectionChange && onSelectionChange(selected);
    };

    const applyClasses = (mask: number) => cn(s.gridArea, { [s.gridAreaActive]: selectionMask & mask });

    useEffect(() => {
        setSelectionMask(mask);
    }, [mask]);

    return (
        <Column rowGap={10}>
            <Row columnGap={10}>
                <Column className={applyClasses(GridAreasMask.TOP_LEFT)} onClick={() => onAreaClick(GridAreasMask.TOP_LEFT)}>
                    {GridAreas.TOP_LEFT}
                </Column>
                <Column className={applyClasses(GridAreasMask.TOP)} onClick={() => onAreaClick(GridAreasMask.TOP)}>
                    {GridAreas.TOP}
                </Column>
                <Column className={applyClasses(GridAreasMask.TOP_RIGHT)} onClick={() => onAreaClick(GridAreasMask.TOP_RIGHT)}>
                    {GridAreas.TOP_RIGHT}
                </Column>
            </Row>
            { !skipSecondRow &&
                <Row columnGap={10}>
                    <Column className={applyClasses(GridAreasMask.LEFT)} onClick={() => onAreaClick(GridAreasMask.LEFT)}>
                        {GridAreas.LEFT}
                    </Column>
                    <Column className={applyClasses(GridAreasMask.CENTER)} onClick={() => onAreaClick(GridAreasMask.CENTER)}>
                        {GridAreas.CENTER}
                    </Column>
                    <Column className={applyClasses(GridAreasMask.RIGHT)} onClick={() => onAreaClick(GridAreasMask.RIGHT)}>
                        {GridAreas.RIGHT}
                    </Column>
                </Row>
            }
            <Row columnGap={10}>
                <Column className={applyClasses(GridAreasMask.BOTTOM_LEFT)} onClick={() => onAreaClick(GridAreasMask.BOTTOM_LEFT)}>
                    {GridAreas.BOTTOM_LEFT}
                </Column>
                <Column className={applyClasses(GridAreasMask.BOTTOM)} onClick={() => onAreaClick(GridAreasMask.BOTTOM)}>
                    {GridAreas.BOTTOM}
                </Column>
                <Column className={applyClasses(GridAreasMask.BOTTOM_RIGHT)} onClick={() => onAreaClick(GridAreasMask.BOTTOM_RIGHT)}>
                    {GridAreas.BOTTOM_RIGHT}
                </Column>
            </Row>
        </Column>
    );
};
