// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App__root--E9x9p {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/base/app/App.scss"],"names":[],"mappings":"AAAA;EACI,OAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;AACJ","sourcesContent":[".root {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `App__root--E9x9p`
};
export default ___CSS_LOADER_EXPORT___;
