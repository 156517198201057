import { useApi } from '@hooks';

export const useStreamerMemePartySettings = () => {
    const {
        memeParty: {
            useResetMemePartySessionMutation,
        },
    } = useApi();

    const [resetMemeParty] = useResetMemePartySessionMutation();

    return {
        resetMemeParty,
    };
};
