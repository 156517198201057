/** Reflects data structure returned by the new OBS token request */
export interface IAuthObsTokenResponse {
    token: string;
}

export interface IMuteUserRequest {
    mutedUserId?: string;
    mutedById?: string;
}

export interface IMuteUserResponse {
    mutedUserId: string;
    mutedById: string;
    reason: string;
    mutedAt: number;
}
