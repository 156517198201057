import { ITag } from '@typings';

export interface IThematicSet {
    id: string;
    name: string;
    tags: ITag[];
    pictureUrl: string;
    beginDate: number;
    endDate: number;
    description: string;
}
