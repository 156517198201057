import { useState } from 'react';

export const useLeftSideMenu = () => {
    const [hideLeftSideBar, setHiddenLeftSideBar] = useState(false);

    return {
        hideLeftSideBar,
        setHiddenLeftSideBar,
    };
};
