import { noop } from '@hyperclap/utils';
import { useEffect, useRef, useState } from 'react';

interface ElementOnScreenParams {
    throttleTimeMs?: number;
    callback?: (isOnScreen: boolean) => void;
}

export const useElementOnScreen = <T extends HTMLElement = HTMLElement>(params: IntersectionObserverInit & ElementOnScreenParams) => {
    const { throttleTimeMs, callback = noop } = params;

    const elementRef = useRef<T>(null);
    const [isOnScreen, setIsOnScreen] = useState(false);

    const isOnScreenRef = useRef(false);
    const isThrottlingRef = useRef(false);

    const intersectionCallback = (entries: IntersectionObserverEntry[]) => {
        const [entry] = entries;

        if (isOnScreen !== entry.isIntersecting) {
            if (throttleTimeMs) {
                if (isThrottlingRef.current) {
                    isOnScreenRef.current = entry.isIntersecting;
                } else {
                    callback(entry.isIntersecting);
                    setIsOnScreen(entry.isIntersecting);

                    isThrottlingRef.current = true;
                    setTimeout(() => {
                        isThrottlingRef.current = false;

                        if (isOnScreenRef.current !== isOnScreen) {
                            callback(isOnScreenRef.current);
                            setIsOnScreen(isOnScreenRef.current);
                        }
                    }, throttleTimeMs);
                }
            } else {
                callback(entry.isIntersecting);
                setIsOnScreen(entry.isIntersecting);
            }
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(intersectionCallback, params);

        if (elementRef.current) {
            observer.observe(elementRef.current);
        }

        return () => {
            if (elementRef.current) {
                observer.unobserve(elementRef.current);
            }
        };
    }, [elementRef, params]);

    return {
        elementRef, isOnScreen,
    };
};
