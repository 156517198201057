import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationType } from '@contexts';
import { useApi, useNotifications } from '@hooks';
import { IChannel } from '@typings';

export const useStreamerPageSettings = () => {
    const { notify } = useNotifications();
    const { t } = useTranslation();

    const {
        channel: {
            useResetChannelAvatarMutation,
            useUpdateChannelAvatarMutation,
            useUpdateChannelBgMutation,
            useCleanChannelBgMutation,
            useUpdateChannelDataMutation,
        },
    } = useApi();

    const [channelAvatarImage, setChannelAvatarImage] = useState<File>();
    const [channelAvatarImageData, setChannelAvatarImageData] = useState('');
    const [isChannelAvatarCleared, setIsChannelAvatarCleared] = useState(false);
    const [channelBgImage, setChannelBgImage] = useState<File>();
    const [channelBgImageData, setChannelBgImageData] = useState('');
    const [isChannelBgCleared, setIsChannelBgCleared] = useState(false);

    const [update, { isSuccess: updateSuccess, isError: updateError, data: updatedPageSettings }] = useUpdateChannelDataMutation();
    const [updateAvatar] = useUpdateChannelAvatarMutation();
    const [resetAvatar] = useResetChannelAvatarMutation();
    const [updateBg] = useUpdateChannelBgMutation();
    const [cleanBg] = useCleanChannelBgMutation();

    const updateChannel = async (data: Partial<IChannel>) => {
        await update(data);
    };

    const updateChannelAvatar = async () => {
        if (channelAvatarImage) {
            const form = new FormData();
            form.append('image', channelAvatarImage);
            await updateAvatar(form);
        }
    };

    const updateChannelBg = async () => {
        if (channelBgImage) {
            const form = new FormData();
            form.append('image', channelBgImage);
            await updateBg(form);
        }
    };

    const cleanChannelAvatar = () => {
        resetAvatar();
    };

    const cleanChannelBg = () => {
        cleanBg();
    };

    const onChangeChannelAvatar = (file: File) => {
        setChannelAvatarImage(file);
    };

    const onChangeChannelBg = (file: File) => {
        setChannelBgImage(file);
    };

    const onCleanBgImage = () => {
        setChannelBgImage(undefined);
        setChannelBgImageData('');
        setIsChannelBgCleared(true);
    };

    const onCleanAvatarImage = () => {
        setChannelAvatarImage(undefined);
        setChannelAvatarImageData('');
        setIsChannelAvatarCleared(true);
    };

    useEffect(() => {
        if (channelAvatarImage) {
            const reader = new FileReader();

            reader.onloadend = () => {
                if (reader.result) {
                    setChannelAvatarImageData(reader.result.toString());
                    setIsChannelAvatarCleared(false);
                }
            };

            reader.readAsDataURL(channelAvatarImage);
        }
    }, [channelAvatarImage]);

    useEffect(() => {
        if (channelBgImage) {
            const reader = new FileReader();

            reader.onloadend = () => {
                if (reader.result) {
                    setChannelBgImageData(reader.result.toString());
                    setIsChannelBgCleared(false);
                }
            };

            reader.readAsDataURL(channelBgImage);
        }
    }, [channelBgImage]);

    useEffect(() => {
        if (updatedPageSettings?.error) {
            notify(updatedPageSettings.error, NotificationType.ERROR);
        } else if (updateSuccess) {
            notify(t('streamer-settings.notification-texts.changes-saved'), NotificationType.SUCCESS);
        } else if (updateError) {
            notify(t('streamer-settings.notification-texts.changes-save-failed'), NotificationType.ERROR);
        }
    }, [updateSuccess, updateError]);

    return {
        channelAvatarImageData,
        channelBgImageData,
        isChannelAvatarCleared,
        isChannelBgCleared,
        updateChannel,
        updateChannelAvatar,
        updateChannelBg,
        cleanChannelBg,
        cleanChannelAvatar,
        onChangeChannelAvatar,
        onChangeChannelBg,
        onCleanAvatarImage,
        onCleanBgImage,
    };
};
