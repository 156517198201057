import { Logger } from '@hyperclap/ui';

import {
    IAvatarAsset, IAvatarAssetCategory,
    IAvatarAssetFace,
    IAvatarAssetFaceEmotion,
    IAvatarAssetFaceEmotionCategory,
    IAvatarAssetLayerPosition, IAvatarAssets, IAvatarAssetSkinColor,
} from '@typings';

import { baseApi } from './base.api';

const logger = new Logger({ target: 'AvatarAssetsApi', showTimestamp: true });
logger.trace(`Configure Avatar Assets API`);

export const avatarAssetsApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [
            'AvatarAssets_AssetsList',
            'AvatarAssets_AssetsCategoriesList',
            'AvatarAssets_AssetsFacesList',
            'AvatarAssets_AssetsFacesEmotionsList',
            'AvatarAssets_AssetsFacesEmotionsCategoriesList',
            'AvatarAssets_AssetsLayerPositionsList',
            'AvatarAssets_AssetsSkinColorList',
            'AvatarAssets_AssetsEyeColorList',
        ],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            loadAvatarAssetsList: build.query<IAvatarAsset[], void>({
                query: () => ({
                    url: 'assets',
                    method: 'GET',
                }),
                providesTags: ['AvatarAssets_AssetsList'],
            }),

            loadAvatarAssetsCategoriesList: build.query<IAvatarAssetCategory[], void>({
                query: () => ({
                    url: 'categories',
                    method: 'GET',
                }),
                providesTags: ['AvatarAssets_AssetsCategoriesList'],
            }),

            loadAvatarAssetsFacesList: build.query<IAvatarAssetFace[], void>({
                query: () => ({
                    url: 'faces',
                    method: 'GET',
                }),
                providesTags: ['AvatarAssets_AssetsFacesList'],
            }),

            loadAvatarAssetsFacesEmotionsList: build.query<IAvatarAssetFaceEmotion[], void>({
                query: () => ({
                    url: 'faces/emotions',
                    method: 'GET',
                }),
                providesTags: ['AvatarAssets_AssetsFacesEmotionsList'],
            }),

            loadAvatarAssetsFacesEmotionsCategoriesList: build.query<IAvatarAssetFaceEmotionCategory[], void>({
                query: () => ({
                    url: 'faces/emotions/categories',
                    method: 'GET',
                }),
                providesTags: ['AvatarAssets_AssetsFacesEmotionsCategoriesList'],
            }),

            loadAvatarAssetsLayerPositionsList: build.query<IAvatarAssetLayerPosition[], void>({
                query: () => ({
                    url: 'layer/position',
                    method: 'GET',
                }),
                providesTags: ['AvatarAssets_AssetsLayerPositionsList'],
            }),

            loadAvatarAssetsSkinColorsList: build.query<IAvatarAssetSkinColor[], void>({
                query: () => ({
                    url: 'skin-color',
                    method: 'GET',
                }),
                providesTags: ['AvatarAssets_AssetsSkinColorList'],
            }),

            loadAvatarAssetsEyeColorsList: build.query<IAvatarAssetSkinColor[], void>({
                query: () => ({
                    url: 'eye-color',
                    method: 'GET',
                }),
                providesTags: ['AvatarAssets_AssetsEyeColorList'],
            }),

            updateAvatar: build.mutation<void, IAvatarAssets>({
                query: (body) => ({
                    url: '',
                    method: 'POST',
                    body,
                }),
            }),
        }),
    });
