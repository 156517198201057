import { ISticker } from '@typings';

export const minIndex = (arr: Array<number>): number => {
    const minValue = Math.min(...arr);

    return arr.indexOf(minValue);
};

export const columnizeStickers = (stickers: Array<ISticker>, columnsCount: number): Array<Array<ISticker>> => {
    const columns: Array<Array<ISticker>> = [];
    const columnsHeight: Array<number> = Array.from({ length: columnsCount }, () => 0);

    stickers.forEach((s) => {
        const minHeightIndex = minIndex(columnsHeight);

        if (!columns[minHeightIndex]) {
            columns[minHeightIndex] = [];
        }

        columns[minHeightIndex].push(s);
        columnsHeight[minHeightIndex] = columns[minHeightIndex]
            .map((s) => s.videoData?.height ?? 0)
            .reduce((acc, curr) => acc = acc + curr);
    });

    return columns;
};
