import { useApi } from '@hooks';

export const useStreamerObsPageSettings = () => {
    const {
        stickers: {
            useSendRandomStickerMutation,
        },
        channel: {
            useObsToggleOverlayMutation,
            useUpdateChannelDataMutation,
        },
    } = useApi();

    const [updateChannel] = useUpdateChannelDataMutation();
    const [toggleObsOverlay] = useObsToggleOverlayMutation();
    const [sendRandomSticker] = useSendRandomStickerMutation();

    return {
        sendRandomSticker,
        toggleObsOverlay,
        updateChannel,
    };
};
