import { IMemePartyState, IMemePartyStateLoadParams, IPosition } from '@typings';

import { baseApi } from './base.api';

export const memePartyApi = baseApi
    .enhanceEndpoints({

    })
    .injectEndpoints({
        endpoints: (build) => ({
            getMemePartyState: build.query<IMemePartyState, IMemePartyStateLoadParams>({
                query: (params: IMemePartyStateLoadParams) => ({
                    url: `meme-party`,
                    method: 'POST',
                    body: params,
                }),
            }),

            resetMemePartySession: build.mutation<IMemePartyState, void>({
                query: () => ({
                    url: `meme-party/reset`,
                    method: 'GET',
                }),
            }),

            updateMemePartyWidgetPosition: build.mutation<void, IPosition>({
                query: (position: IPosition) => ({
                    url: `meme-party/update-position`,
                    method: 'PUT',
                    body: position,
                }),
            }),
        }),
    });
