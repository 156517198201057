import { IAvatarAssets } from './AvatarAssets';
import { IChannel } from './Channel';

export interface IFlags {
    isAdmin?: boolean;
    isModer?: boolean;
    isScout?: boolean;
    isMasterModer?: boolean;
}

export interface IUser {
    _id: string;
    id: string;
    name: string;
    username: string;
    avatar: string;
    avatarAssets: IAvatarAssets;
    flags: IFlags;
    createdAt: number;
    deletedAt: number;
    channel: IChannel;
    linkedAccounts: {
        google: {
            email: string;
        };
        twitch: {
            username: string;
        };
        vk: {
            username: string;
        };
    };
}

export interface ISupportedStreamer {
    streamerName: string,
    streamerLink: string,
    streamerAvatar: string,
    streamerId: string,
}

export interface IGiveBonusParams {
    userId: string;
    streamerId: string;
    value: number;
}

export interface IWalletState {
    balance: number;
    welcomeBonusEarned: boolean;
    newbieActionUsed: boolean;
}
