import { useEffect } from 'react';

import { useApi, useCurrentUser, useStreamer, useWebSocket } from '@hooks';
import { TWSMessage, WSMessageType } from '@typings';

interface IMuteParams {
    streamerChannelName?: string;
}

export const useMute = (params: IMuteParams) => {
    const {
        streamerChannelName,
    } = params;

    const {
        currentUser,
    } = useCurrentUser();

    const {
        refetchStreamer,
    } = useStreamer({ streamerChannelName });

    const {
        mute: {
            useMuteUserMutation,
            useUnmuteUserMutation,
        },
    } = useApi();

    const {
        subscribe,
        unsubscribe,
    } = useWebSocket();

    const [muteUserUsingId] = useMuteUserMutation();
    const [unmuteUserUsingId] = useUnmuteUserMutation();

    const muteUser = async (userId: string) => {
        return await muteUserUsingId({
            mutedUserId: userId,
            mutedById: currentUser?.id,
        }).unwrap();
    };

    const unmuteUser = async (userId: string) => {
        return await unmuteUserUsingId({
            mutedUserId: userId,
            mutedById: currentUser?.id,
        }).unwrap();
    };

    const messageHandler = (message: TWSMessage) => {
        switch (message.type) {
            case WSMessageType.MUTE_STATE_CHANGED: {
                refetchStreamer();
                break;
            }

            case WSMessageType.BAN_STATE_CHANGED: {
                break;
            }
        }
    };

    useEffect(() => {
        if (currentUser) {
            subscribe(currentUser.id, messageHandler);
        }

        return () => {
            if (currentUser) unsubscribe(currentUser.id, messageHandler);
        };
    }, [currentUser]);

    return {
        muteUser,
        unmuteUser,
    };
};
