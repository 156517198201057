import { Column, EComponentSize, ERowAlignment, Label, Loader, Row } from '@hyperclap/ui';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { IconMagnifierLine } from '@assets/images/svg';
import { Avatar } from '@components';
import { TApp, useStreamerInfo } from '@hooks';
import { useNavigator } from '@hooks/app/navigator';
import { EtmaRating, IUser } from '@typings';
import { Empty, ITabParams, Tabs } from '@views/fragments';
import { MoneyTable, StickersTable } from '@views/fragments/StreamerView/components';

import s from './StreamerView.scss';

export enum StreamerInfoMode {
    MONEY,
    STICKERS,
}

export interface IStreamerViewProps extends TApp {
    query: string;
}

export const StreamerView = (props: IStreamerViewProps) => {
    const { query: queryParam } = useParams();
    const {
        translation: {
            t,
        },
        query,
    } = props;

    const [streamerInfoMode, setStreamerInfoMode] = useState<StreamerInfoMode>(StreamerInfoMode.MONEY);

    const {
        streamer,
        isFetching,
        isNotFound,
    } = useStreamerInfo({ query });

    const { goToStreamersSearch } = useNavigator();

    const TABS: Array<ITabParams<StreamerInfoMode>> = [
        { caption: t('moderator-area.streamers.search.money'), tag: StreamerInfoMode.MONEY },
        { caption: t('moderator-area.streamers.search.stickers'), tag: StreamerInfoMode.STICKERS },
    ];

    const onTabSelected = (mode: StreamerInfoMode = StreamerInfoMode.MONEY) => {
        setStreamerInfoMode(mode);
    };

    const getAuthorizationWayText = (viewer: IUser) => {
        return viewer.linkedAccounts.google ? `Google (${viewer.linkedAccounts.google.email})`
            : viewer.linkedAccounts.vk ? `VK (${viewer.linkedAccounts.vk.username})`
                : `Twitch (${viewer.linkedAccounts.twitch.username})`;
    };

    const onI18 = t('moderator-area.streamers.view.on');
    const offI18 = t('moderator-area.streamers.view.off');

    useEffect(() => {
        if (streamer && !isFetching) {
            if (!queryParam || streamer.channel.uniqueLink !== queryParam) {
                goToStreamersSearch(streamer.channel.uniqueLink);
            }
        } else if (!isFetching && isNotFound) {
            goToStreamersSearch();
        }
    }, [streamer, isFetching, isNotFound]);

    return (
        <Column className={s.statsContainer}>
            {streamer &&
                <Row>
                    <Column grow rowGap={20}>
                        <Row>
                            <Column>
                                <Row columnGap={15}>
                                    <Column>
                                        <Avatar
                                            source={streamer.channel.avatarUrl}
                                            width={40}
                                            height={40}
                                            style={{ borderRadius: 5 }}
                                        />
                                    </Column>
                                    <Column>
                                        <Label caption={streamer.name} size={EComponentSize.LARGE}/>
                                    </Column>
                                </Row>
                                <Row style={{ marginTop: 15 }}>
                                    <Label
                                        caption={t('moderator-area.streamers.view.da', { da: streamer.channel.donationAlertsUsername })}
                                        muted
                                        style={{ paddingTop: 0, paddingBottom: 3 }}
                                        size={EComponentSize.SMALL}/>
                                </Row>
                                <Row>
                                    <Label
                                        caption={t('moderator-area.streamers.view.auth-type', { type: getAuthorizationWayText(streamer) })}
                                        muted
                                        style={{ paddingTop: 0 }}
                                        size={EComponentSize.SMALL}/>
                                </Row>
                            </Column>
                        </Row>
                        <Row columnGap={80} alignment={ERowAlignment.TOP}>
                            <Column>
                                <Row>
                                    <Label
                                        caption={
                                            t('moderator-area.streamers.view.disabled-stickers',
                                                { state: streamer.channel.disableStickers
                                                    ? offI18
                                                    : onI18,
                                                },
                                            )}
                                        muted
                                        style={{ paddingTop: 0 }}
                                        size={EComponentSize.SMALL}/>
                                </Row>
                                <Row>
                                    <Label
                                        caption={
                                            t('moderator-area.streamers.view.etma',
                                                { etma: EtmaRating[streamer.channel.etmaRating].toString() },
                                            )}
                                        muted
                                        style={{ paddingTop: 0 }}
                                        size={EComponentSize.SMALL}/>
                                </Row>
                                <Row>
                                    <Label
                                        caption={
                                            t('moderator-area.streamers.view.channel-only',
                                                { state: streamer.channel.onlyChannelStickers
                                                    ? onI18
                                                    : offI18,
                                                },
                                            )}
                                        muted
                                        style={{ paddingTop: 0 }}
                                        size={EComponentSize.SMALL}/>
                                </Row>
                                <Row>
                                    <Label
                                        caption={
                                            t('moderator-area.streamers.view.twitch-safe',
                                                { state: streamer.channel.onlyTwitchFriendly
                                                    ? onI18
                                                    : offI18,
                                                },
                                            )}
                                        muted
                                        style={{ paddingTop: 0 }}
                                        size={EComponentSize.SMALL}/>
                                </Row>
                            </Column>
                            <Column>
                                <Row>
                                    <Label
                                        caption={
                                            t('moderator-area.streamers.view.copyright',
                                                { state: streamer.channel.isCopyrightFreeOnly
                                                    ? onI18
                                                    : offI18,
                                                },
                                            )}
                                        muted
                                        style={{ paddingTop: 0 }}
                                        size={EComponentSize.SMALL}/>
                                </Row>
                                <Row>
                                    <Label
                                        caption={
                                            t('moderator-area.streamers.view.messages',
                                                { state: streamer.channel.isStickersMessagesEnabled
                                                    ? onI18
                                                    : offI18,
                                                },
                                            )}
                                        muted
                                        style={{ paddingTop: 0 }}
                                        size={EComponentSize.SMALL}/>
                                </Row>
                                <Row>
                                    <Label
                                        caption={
                                            t('moderator-area.streamers.view.swear-filter',
                                                { state: streamer.channel.isAntiSwearEnabled
                                                    ? onI18
                                                    : offI18,
                                                },
                                            )}
                                        muted
                                        style={{ paddingTop: 0 }}
                                        size={EComponentSize.SMALL}/>
                                </Row>
                                <Row>
                                    <Label
                                        caption={
                                            t('moderator-area.streamers.view.meme-party',
                                                { state: streamer.channel.memePartyActive
                                                    ? onI18
                                                    : offI18,
                                                },
                                            )}
                                        muted
                                        style={{ paddingTop: 0 }}
                                        size={EComponentSize.SMALL}/>
                                </Row>
                            </Column>
                        </Row>
                        <Row>
                            <Tabs activeTab={streamerInfoMode} onSelectionChange={onTabSelected} tabs={TABS}/>
                        </Row>
                    </Column>
                </Row>
            }
            {streamer && streamerInfoMode === StreamerInfoMode.MONEY &&
                <Row>
                    <MoneyTable {...props} streamer={streamer} />
                </Row>
            }
            {streamer && streamerInfoMode === StreamerInfoMode.STICKERS &&
                <Row>
                    <StickersTable {...props} streamer={streamer} />
                </Row>
            }
            {!isFetching && isNotFound &&
                <Row>
                    <Empty style={{ marginTop: 20 }} icon={<IconMagnifierLine/>} text={t('moderator-area.streamers.view.not-found')}/>
                </Row>
            }
            {isFetching &&
                <Row>
                    <Loader style={{ marginTop: 20, marginBottom: 3 }}/>
                </Row>
            }
        </Column>
    );
};
