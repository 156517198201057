import {
    Loader,
    EComponentColor,
    EComponentSize,
} from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import cn from 'classnames';
import React, { useRef } from 'react';

import { IconCloseLine } from '@assets/images/svg';
import { Avatar } from '@components';
import { IUser } from '@typings';

import { useStreamerSelect } from './hooks/StreamerSelect';
import s from './StreamerSelect.scss';

export interface StreamerSelectMethods {
    reset: () => void;
    focus: () => void;
    set: (value: string) => void;
}

export interface StreamerSelectDefaultValue {
    id: string;
    avatarUrl?: string;
    name?: string;
}

interface StreamerSelectProps {
    className?: string;
    color?: EComponentColor;
    debounceTime?: number;
    defaultValue?: StreamerSelectDefaultValue;
    methodsRef?: React.MutableRefObject<StreamerSelectMethods | null>;
    invalidSymbols?: RegExp;
    maxLength?: number;
    maxWidth?: number;
    minWidth?: number;
    fullWidth?: boolean;
    placeholder?: string;
    readonly?: boolean;
    onSelect?: (streamer?: IUser) => void;
}

export const StreamerSelect = (props: StreamerSelectProps) => {
    const {
        className,
        color = EComponentColor.ACCENT,
        methodsRef,
        maxWidth,
        minWidth,
        fullWidth,
        placeholder,
        onSelect = noop,
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);
    const {
        isLoadInProgress,
        searchResults,
        selectedStreamer,
        errorUrls,
        value,
        onChange,
        setSelectedStreamer,
        set,
        reset,
    } = useStreamerSelect({ ...props });

    const styles = {
        minWidth,
        maxWidth,
    };

    const focus = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const onStreamerClick = (streamer: IUser) => {
        setTimeout(() => {
            setSelectedStreamer(streamer);
            onSelect(streamer);
        }, 0);
    };

    const textFieldClassNames = cn(
        s.textField,
        className,
        s['color' + color],
        {
            [s.textFieldFullWidth]: fullWidth,
        },
    );

    if (methodsRef) {
        methodsRef.current = {
            reset,
            focus,
            set,
        };
    }

    const getStreamerItem = (user: IUser, onSelect?: (user: IUser) => void) => {
        return (
            <div
                key={user._id}
                onMouseDown={() => onSelect ? onSelect(user) : noop}
                className={cn(s.streamerItem, { [s.streamerItemSelectable]: !!onSelect })}>
                <div className={s.streamerItemIcon}>
                    <Avatar
                        className={s.streamerItemIconAvatar}
                        source={errorUrls?.includes(user.channel.avatarUrl || '') ? '' : user.channel.avatarUrl}
                        size={EComponentSize.EXTRA_SMALL}
                        rounded
                    />
                </div>
                <div className={s.streamerItemText}>{user.channel.name}</div>
            </div>
        );
    };

    return (
        <div className={textFieldClassNames} style={styles} onBlur={() => reset()}>
            <div className={s.textFieldInputContainer}>
                <input
                    className={cn(s.textFieldInput, {
                        [s.textFieldInputInvisible]: !!selectedStreamer,
                    })}
                    placeholder={placeholder}
                    ref={inputRef}
                    value={value}
                    onChange={onChange}
                />
                { selectedStreamer &&
                    <div className={s.textFieldSelectedStreamer}>
                        { getStreamerItem(selectedStreamer) }
                    </div>
                }
            </div>

            <div className={s.textFieldActionsContainer}>
                { !selectedStreamer
                    ? <div className={s.textFieldAction}>
                        <Loader
                            containerClassName={s.loader}
                            className={cn({ [s.loaderHidden]: !isLoadInProgress })}
                            size={20}
                        />
                    </div>
                    : <div
                        className={cn(
                            s.textFieldAction,
                            {
                                [s.textFieldActionHidden]: !selectedStreamer,
                            },
                        )}
                        onClick={reset}>
                        <IconCloseLine/>
                    </div>
                }
            </div>

            {(searchResults && searchResults?.length > 0) &&
                <div className={s.textFieldDropdown}>
                    { searchResults?.map((res) => getStreamerItem(res, onStreamerClick)) }
                </div>
            }
        </div>
    );
};
