import {
    EventKind, IBonusEarnedEvent, IBonusIssuedEvent,
    IBuyCurrencyEvent,
    IEventData,
    IMemeCannonStickerSentEvent, IModerationStatusEvent,
    IStickerSentEvent, IWelcomeBonusEarnedEvent,
    TMemeAlertsEvent,
} from '@typings';

export function mapDataToEvent(data: TMemeAlertsEvent) {
    const result: Partial<IEventData> = {};
    result.id = data.id;
    result._id = data.id;
    result.timestamp = data.timestamp;

    switch (data.kind) {
        case EventKind.STICKER_SENT: {
            const stickerSentEvent = data as IStickerSentEvent;
            result.kind = EventKind.STICKER_SENT;
            result.stickerName = stickerSentEvent.stickerName;
            result.coins = stickerSentEvent.paidPrice;
            result.fullscreen = stickerSentEvent.fullscreen;
            result.message = stickerSentEvent.message;
            result.userName = stickerSentEvent.userAlias;
            break;
        }

        case EventKind.MEME_CANNON_STICKER_SENT: {
            const memeCannonSentEvent = data as IMemeCannonStickerSentEvent;
            result.kind = EventKind.MEME_CANNON_STICKER_SENT;
            result.stickerName = memeCannonSentEvent.stickerName;
            result.coins = memeCannonSentEvent.paidPrice;
            result.memeCannonCount = memeCannonSentEvent.count;
            result.message = memeCannonSentEvent.message;
            result.userName = memeCannonSentEvent.userAlias;
            break;
        }

        case EventKind.BUY_CURRENCY: {
            const buyCurrencyEvent = data as IBuyCurrencyEvent;
            result.kind = EventKind.BUY_CURRENCY;
            result.coins = buyCurrencyEvent.packValue;
            result.packPrice = buyCurrencyEvent.packPrice;
            result.userName = buyCurrencyEvent.userName;
            break;
        }

        case EventKind.WELCOME_BONUS_EARNED: {
            const welcomeBonusEvent = data as IWelcomeBonusEarnedEvent;
            result.kind = EventKind.WELCOME_BONUS_EARNED;
            result.coins = welcomeBonusEvent.bonusValue;
            result.userName = welcomeBonusEvent.userName;
            break;
        }

        case EventKind.BONUS_EARNED: {
            const bonusEvent = data as IBonusEarnedEvent;
            result.kind = EventKind.BONUS_EARNED;
            result.coins = bonusEvent.bonusValue;
            result.userName = bonusEvent.userName;
            break;
        }

        case EventKind.BONUS_ISSUED: {
            const bonusIssuedEvent = data as IBonusIssuedEvent;
            result.kind = EventKind.BONUS_ISSUED;
            result.coins = bonusIssuedEvent.bonusValue;
            result.userName = bonusIssuedEvent.userName;
            break;
        }

        case EventKind.MODERATION_STATUS: {
            const moderationStatusEvent = data as IModerationStatusEvent;
            result.kind = EventKind.MODERATION_STATUS;
            result.stickerName = moderationStatusEvent.stickerName;
            result.status = moderationStatusEvent.status;
            result.reason = moderationStatusEvent.reason;
            break;
        }
    }

    return result;
}
