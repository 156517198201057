import { bindActionCreators } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { appSliceActions } from '@store';

const actions = {
    ...appSliceActions,
};

export const useAppActions = () => {
    const dispatch = useDispatch();

    return bindActionCreators(actions, dispatch);
};
