import cn from 'classnames';
import React from 'react';

import s from './EmptyPage.scss';

interface IEmptyPageProps {
    className?: string;
}

export const EmptyPage = (props: React.PropsWithChildren<IEmptyPageProps>) => {
    const { children, className } = props;

    return (
        <div className={cn(s.emptyPage, className)}>
            { children }
        </div>
    );
};
