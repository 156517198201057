import { useEffect, useRef } from 'react';

import { WebSocketClient, WebSocketClientParams } from '@common';
import { TOptional, TWSMessage } from '@typings';

export const useWebSocket = (params?: WebSocketClientParams) => {
    const webSocketRef = useRef<WebSocketClient>();

    const subscribe = (channel: TOptional<string>, callback: (data: TWSMessage, callback?: (data: unknown) => void) => void) => {
        if (channel) webSocketRef.current?.subscribe(channel, callback);
    };

    const unsubscribe = (channel: TOptional<string>, callback: (data: TWSMessage, callback?: (data: unknown) => void) => void) => {
        if (channel) webSocketRef.current?.unsubscribe(channel, callback);
    };

    const emit = (channel: TOptional<string>, data: unknown) => {
        if (channel) webSocketRef.current?.emit(channel, data);
    };

    useEffect(() => {
        webSocketRef.current = WebSocketClient.getInstance(params);
    }, []);

    return {
        subscribe,
        unsubscribe,
        emit,
    };
};
