export interface IPosition {
    x: number;
    y: number;
}

export interface IGeometry extends IPosition {
    rotation?: number;
    scale?: number;
}

export const DEFAULT_GEOMETRY: IGeometry = {
    x: 0,
    y: 0,
    scale: 1,
    rotation: 0,
};

export interface IDimensions {
    width: number;
    height: number;
}

export const DEFAULT_DIMENSIONS: IDimensions = {
    width: 0,
    height: 0,
};

export enum SizeCalculationBase {
    NONE,
    WIDTH,
    HEIGHT,
}

export enum BackgroundMode {
    COVER,
    TILE,
}

