import {
    IEventData,
    IEventsFilters,
    ISendStickerDialogOpenedEventRegistrationParams, IStreamerPageOpenEventRegistrationParams,
    ISupporterEventsFilters,
    TMemeAlertsEvent,
} from '@typings';
import { IPageOf, TLoadPageUsing } from '@typings/common/Paging';

import { baseApi } from './base.api';

export const eventsApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [
            'Events_DashboardEventsList',
            'Events_List',
            'Events_SupporterEventList',
        ],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            // TODO: This is an old method to load events, it should be replaced by loadEventsPage
            loadDashboardEvents: build.query<Array<TMemeAlertsEvent>, TLoadPageUsing<IEventsFilters>>({
                query: (params: TLoadPageUsing<IEventsFilters>) => ({
                    url: 'event',
                    method: 'POST',
                    // TODO: remove limit to count transformation after refactoring of the backend
                    body: { ...params },
                }),
                providesTags: ['Events_DashboardEventsList'],
            }),
            loadEventsForPeriod: build.query<IPageOf<IEventData>, TLoadPageUsing<IEventsFilters>>({
                query: (params: TLoadPageUsing<IEventsFilters>) => ({
                    url: 'event/period',
                    method: 'POST',
                    body: { ...params },
                }),
                providesTags: ['Events_List'],
            }),
            loadEventsForSupporter: build.query<IPageOf<IEventData>, TLoadPageUsing<ISupporterEventsFilters>>({
                query: (params: TLoadPageUsing<ISupporterEventsFilters>) => ({
                    url: 'event/supporter',
                    method: 'POST',
                    body: params,
                }),
                providesTags: ['Events_SupporterEventList'],
            }),

            // Client-side events registration API
            registerSendStickerDialogOpenEvent: build.mutation<void, ISendStickerDialogOpenedEventRegistrationParams>({
                query: (eventData: ISendStickerDialogOpenedEventRegistrationParams) => ({
                    url: 'event/send-sticker-popup-open',
                    method: 'POST',
                    body: eventData,
                }),
            }),

            registerStreamerPageOpenEvent: build.mutation<void, IStreamerPageOpenEventRegistrationParams>({
                query: (eventData: IStreamerPageOpenEventRegistrationParams) => ({
                    url: 'event/send-streamer-page-open',
                    method: 'POST',
                    body: eventData,
                }),
            }),

            loadEvents: build.query<Array<TMemeAlertsEvent>, TLoadPageUsing<IEventsFilters>>({
                query: (params: TLoadPageUsing<IEventsFilters>) => ({
                    url: 'event',
                    method: 'POST',
                    body: params,
                }),
            }),

            hasUnreadEvents: build.query<boolean, void>({
                query: () => ({
                    url: 'event/read/state',
                    method: 'POST',
                }),
            }),

            markAsRead: build.query<boolean, void>({
                query: () => ({
                    url: 'event/read',
                    method: 'POST',
                }),
            }),

        }),
    });
