import { NavigateOptions, useNavigate } from 'react-router-dom';

import { StickersListMode } from '@hooks';

export const useNavigator = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);
    const goToPath = (path: string, options?: NavigateOptions) => navigate(path, options);

    const goToHome = () => navigate('/');
    const goToModerationRoom = () => goToPath('/moderation/room/editor');
    const goToModerationStickersTab = (mode: StickersListMode = StickersListMode.APPROVED, query?: string, index?: string) =>
        goToPath(`/moderation/stickers/search/${mode.toLowerCase()}${index ? '/' + index : ''}${query ? '?query=' + query : ''}`);
    const goToStickersEditor = (stickerId: string) => goToPath(`/moderation/stickers/editor/${stickerId}`);
    const goToViewersSearch = (userId?: string) => goToPath(`/moderation/viewers/search${userId ? '/' + userId: ''}`);
    const goToStreamersSearch = (query?: string) => goToPath(`/moderation/streamers/search${query ? '/' + query: ''}`);

    return {
        goBack,

        goToHome,
        goToModerationRoom,
        goToModerationStickersTab,
        goToStickersEditor,
        goToViewersSearch,
        goToStreamersSearch,
        goToPath,
    };
};
