import { EComponentSize, ScrollableArea } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { IconSmileBeamSolid } from '@assets/images/svg';
import { Avatar } from '@components';
import { ISupportedStreamer } from '@typings';
import { Empty } from '@views';

import s from './StreamersList.scss';

interface StreamersListProps {
    className?: string;
    useLargeSize?: boolean;
    streamers?: Array<ISupportedStreamer>;
    onClick?: (streamer: ISupportedStreamer) => void;
}

export const StreamerList = (props: StreamersListProps) => {
    const {
        className,
        useLargeSize,
        streamers = [],
        onClick = noop,
    } = props;

    const linkClasses = cn({
        [s.streamer]: true,
        [s.streamerMobileLayout]: useLargeSize,
    });

    const items = streamers.map((streamer, idx) => (
        <Link className={linkClasses} onClick={() => onClick(streamer)} to={`/${streamer.streamerLink}`} target="_blank" key={idx}>
            <Avatar
                className={cn(s.streamerAvatar, { [s.streamerAvatarMobile]: useLargeSize })}
                size={useLargeSize ? EComponentSize.MEDIUM : EComponentSize.SMALL}
                source={streamer.streamerAvatar}
                rounded
            />
            <div className={s.streamerText} title={streamer.streamerName}>{streamer.streamerName}</div>
        </Link>
    ));

    return (
        streamers.length > 0
            ? <>
                { !useLargeSize && <div className={s.hr}/> }
                <ScrollableArea
                    className={cn(s.streamersList, className)}
                    contentClassName={cn(s.streamersListContent, { [s.streamersListContentMaxWidth]: !useLargeSize })}
                >
                    {items}
                </ScrollableArea>
            </>
            : useLargeSize
                ? <Empty
                    icon={<IconSmileBeamSolid/>}
                    iconSize={42}
                    margin={{ top: 65 }} text={'Здесь будет список ваших\nлюбимых стримеров.'}
                />
                : null
    );
};
