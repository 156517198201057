import { Button, Column, EComponentColor, Label, Loader, Row, Switch, useModals } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import { t } from 'i18next';
import React, { createRef, useState } from 'react';

import { IconCloseLine } from '@assets/images/svg';
import { IGame, IMassStickersUpdate, ITag, IUser } from '@typings';
import { GameSelect, StreamerSelect, TagSelect } from '@views/fragments';

import s from './MassTagPopup.scss';

interface MassTagPopupProps {
    filter: string;
    stickersCount?: number;
    massUpdateStickers?: (data: IMassStickersUpdate) => Promise<number>;
}

export const MassTagPopup = (props: MassTagPopupProps) => {
    const { closeModal } = useModals();
    const {
        filter,
        stickersCount,
        massUpdateStickers = noop,
    } = props;

    const approveLayerRef = createRef<HTMLDivElement>();

    const [streamer, setStreamer] = useState<IUser>();
    const [game, setGame] = useState<IGame>();
    const [addedTags, setAddedTags] = useState<ITag[]>();
    const [removedTags, setRemovedTags] = useState<ITag[]>();
    const [isNotTwitchSafe, setIsNotTwitchSafe] = useState<boolean>(false);

    const [isVisibleApproveLayer, setIsVisibleApproveLayer] = useState(false);
    const [isEditInProcess, setIsEditInProcess] = useState(false);

    const isValidValues =
        (filter.length > 0 &&
            (game ||
                streamer ||
                (addedTags && addedTags.length > 0) ||
                (removedTags && removedTags.length > 0) ||
                isNotTwitchSafe));

    const onApproveShadowLayerClick = (event: React.MouseEvent<HTMLElement>) => {
        if (event.target !== approveLayerRef.current) return;
        event.stopPropagation();

        setIsVisibleApproveLayer(false);
    };

    const onApproveCancelClick = async () => {
        setIsVisibleApproveLayer(false);
    };

    const onApproveClick = async () => {
        if (isValidValues) {
            try {
                setIsEditInProcess(true);
                await massUpdateStickers({
                    query: filter,
                    streamerId: streamer?._id,
                    gameId: game?._id,
                    addedTags,
                    removedTags,
                    isNotTwitchSafe,
                });
                closeModal();
            } catch {
                setIsEditInProcess(false);
            }
        }
    };

    return (
        <div className={s.massTagPopup}>
            { isVisibleApproveLayer &&
                <div className={s.approveLayer} ref={approveLayerRef} onClick={onApproveShadowLayerClick}>
                    <div className={s.content}>
                        <div className={s.question}>{t('mass-tag-popup.apply-title')}</div>
                        <div className={s.textHint}>
                            {t('mass-tag-popup.apply-changes', { stickersCount: stickersCount ?? '' })}
                        </div>
                        {isEditInProcess
                            ? <div className={s.loader}>
                                <Loader className={s.spinner}/>
                            </div>
                            : <div className={s.buttons}>
                                <Button
                                    caption={t('common.yes')}
                                    fullWidth
                                    onClick={onApproveClick}
                                    color={EComponentColor.DANGER}
                                />
                                <Button
                                    caption={t('common.no')}
                                    fullWidth
                                    onClick={onApproveCancelClick}
                                    color={EComponentColor.ACCENT}
                                />
                            </div>
                        }
                    </div>
                </div>
            }
            <Column grow rowGap={10} className={s.massTagPopupContent}>
                <Label caption={t('mass-tag-popup.mass-edit')} className={s.massTagPopupTopicLabel} />
                <Row>
                    <StreamerSelect
                        debounceTime={500}
                        fullWidth
                        placeholder={t('mass-tag-popup.streamer')}
                        onSelect={(streamer) => setStreamer(streamer)}
                    />
                </Row>
                <Row>
                    <GameSelect
                        fullWidth
                        placeholder={t('mass-tag-popup.game')}
                        debounceTime={500}
                        maxLength={100}
                        onSelect={(game) => setGame(game)}
                    />
                </Row>
                <Row>
                    <TagSelect
                        fullWidth
                        placeholder={t('mass-tag-popup.add-tag')}
                        debounceTime={500}
                        maxLength={20}
                        onTagsChanged={setAddedTags}
                    />
                </Row>
                <Row>
                    <TagSelect
                        fullWidth
                        placeholder={t('mass-tag-popup.delete-tag')}
                        debounceTime={500}
                        maxLength={20}
                        onTagsChanged={setRemovedTags}
                    />
                </Row>
                <Row>
                    <Switch
                        style={{ marginTop: 10, marginLeft: 15 }}
                        color={EComponentColor.TWITCH}
                        caption={t('moderator-area.common.twitch-unsafe')}
                        onChange={(value) => setIsNotTwitchSafe(value)}
                    />
                </Row>
                <Row className={s.footerRow}>
                    <Button
                        color={EComponentColor.WARNING}
                        caption={t('common.save')}
                        onClick={() => isValidValues && setIsVisibleApproveLayer(true)}
                        disabled={!isValidValues}
                    />
                </Row>
            </Column>

            <div className={s.closeButton} onClick={closeModal}>
                <IconCloseLine/>
            </div>
        </div>
    );
};
