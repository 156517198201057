import { noop } from '@hyperclap/utils';
import cn from 'classnames';
import React from 'react';

import { TApp } from '@hooks';
import { useNavigator } from '@hooks/app/navigator';
import { EventKind, IViewerInfoEventItem } from '@typings';

import s from './EventsTable.scss';

export interface IEventsTableProps extends TApp {
    events: IViewerInfoEventItem[];
}

export const EventsTable = (props: IEventsTableProps) => {
    const {
        translation: {
            t,
        },
        events,
    } = props;

    const { goToStreamersSearch, goToStickersEditor } = useNavigator();

    const getEventType = (event: IViewerInfoEventItem) => {
        switch (event.kind) {
            case EventKind.STICKER_SENT:
                return event.fullscreen ? 'FullScreen' : t('common.sticker');
            case EventKind.BONUS_EARNED:
            case EventKind.BONUS_ISSUED:
                return t('common.bonus');
            case EventKind.BUY_CURRENCY:
                return t('common.purchase');
            case EventKind.MEME_CANNON_STICKER_SENT:
                return t('common.meme-cannon');
            case EventKind.WELCOME_BONUS_EARNED:
                return t('common.bonus');
            case EventKind.MODERATION_STATUS:
                return t('common.moderation');
            default:
                return '-';
        }
    };

    const getEventRowClassname = (event: IViewerInfoEventItem) => {
        switch (event.kind) {
            case EventKind.BONUS_EARNED:
            case EventKind.WELCOME_BONUS_EARNED:
            case EventKind.BONUS_ISSUED:
                return 'eventBgBonus';
            case EventKind.BUY_CURRENCY:
                return 'eventBgPurchase';
            case EventKind.STICKER_SENT:
                return event.fullscreen ? 'eventBgFullscreen' : 'eventBgSticker';
            case EventKind.MEME_CANNON_STICKER_SENT:
                return 'eventBgMemeCannon';
            default:
                return '';
        }
    };

    const getEventCellClassname = (event: IViewerInfoEventItem) => {
        switch (event.kind) {
            case EventKind.STICKER_SENT:
                return event.fullscreen ? 'eventFgFullscreen' : 'eventFgSticker';
            case EventKind.MEME_CANNON_STICKER_SENT:
                return 'eventFgMemeCannon';
            default:
                return '';
        }
    };

    const getEventDirection = (item: IViewerInfoEventItem) => {
        const kind = item.kind;

        return kind === EventKind.BUY_CURRENCY || kind === EventKind.WELCOME_BONUS_EARNED || kind === EventKind.BONUS_EARNED
            ? t('moderator-area.viewers.view.events-table.income') : t('moderator-area.viewers.view.events-table.spending');
    };

    const formatDate = (date: Date | number) => {
        const d = typeof date === 'number' ? new Date(date) : date;

        return d.toLocaleTimeString('ru', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        });
    };

    const rows = events.map((e) => {
        const eventType = getEventType(e);
        const rowClass = getEventRowClassname(e);
        const cellClass = getEventCellClassname(e);

        return (
            <tr key={e._id} className={cn(s.eventsTableBodyRow, s[rowClass])}>
                <td className={s.eventsTableBodyRowCell}>{formatDate(e.timestamp)}</td>
                <td
                    className={
                        cn(
                            s.eventsTableBodyRowCell,
                            s.eventsTableBodyRowCellClickable,
                            s[cellClass],
                        )}
                    onClick={() => goToStreamersSearch(e.streamerLink)}
                >{e.streamerName}</td>
                <td className={cn(s.eventsTableBodyRowCell, s[cellClass])}>{getEventDirection(e)}</td>
                <td className={cn(s.eventsTableBodyRowCell, s[cellClass])}>{eventType}</td>
                <td
                    className={
                        cn(
                            s.eventsTableBodyRowCell,
                            s[cellClass],
                            {
                                [s.eventsTableBodyRowCellClickable]: e.stickerId,
                            },
                        )}
                    onClick={() => e.stickerId ? goToStickersEditor(e.stickerId) : noop }
                >{e.stickerName}</td>
                <td className={cn(s.eventsTableBodyRowCell, s[cellClass])}>{e.paidPrice || e.packValue || e.bonusValue || 0}</td>
                <td className={cn(s.eventsTableBodyRowCell, s[cellClass])}>{e.packPrice}</td>
                <td className={cn(s.eventsTableBodyRowCell, s[cellClass])}>{e.balance}</td>
            </tr>
        );
    });

    return (
        <table className={s.eventsTable}>
            <thead>
                <tr className={s.eventsTableHeadRow}>
                    <td className={s.eventsTableHeadRowCell}>
                        {t('moderator-area.viewers.view.events-table.date')}
                    </td>
                    <td className={s.eventsTableHeadRowCell}>
                        {t('moderator-area.viewers.view.events-table.streamer')}
                    </td>
                    <td className={s.eventsTableHeadRowCell}>
                        {t('moderator-area.viewers.view.events-table.type')}
                    </td>
                    <td className={s.eventsTableHeadRowCell}>
                        {t('moderator-area.viewers.view.events-table.event')}
                    </td>
                    <td className={s.eventsTableHeadRowCell}>
                        {t('moderator-area.viewers.view.events-table.sticker')}
                    </td>
                    <td className={s.eventsTableHeadRowCell}>
                        {t('moderator-area.viewers.view.events-table.coins')}
                    </td>
                    <td className={s.eventsTableHeadRowCell}>
                        {t('moderator-area.viewers.view.events-table.money')}
                    </td>
                    <td className={s.eventsTableHeadRowCell}>
                        {t('moderator-area.viewers.view.events-table.balance')}
                    </td>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>
    );
};
