import { Button, Column, Loader, Row } from '@hyperclap/ui';
import cn from 'classnames';
import React from 'react';

import { IconMagnifierLine } from '@assets/images/svg';
import { TApp, useStreamerInfoStickers } from '@hooks';
import { useNavigator } from '@hooks/app/navigator';
import { EventKind, IStreamerInfoStickersItem, IUser } from '@typings';
import { Empty } from '@views';

import s from './StickersTable.scss';

export interface IStickersTableProps extends TApp {
    streamer?: IUser;
}

export const StickersTable = (props: IStickersTableProps) => {
    const {
        translation: {
            t,
        },
        streamer,
    } = props;

    const {
        currentStats,
        isLoadingPage,
        isFetchingPage,
        isEndReached,
        loadNextPage,
    } = useStreamerInfoStickers({ streamer });

    const { goToViewersSearch, goToStickersEditor } = useNavigator();

    const getEventRowClassname = (event: IStreamerInfoStickersItem) => {
        switch (event.kind) {
            case EventKind.BONUS_EARNED:
            case EventKind.WELCOME_BONUS_EARNED:
            case EventKind.BONUS_ISSUED:
                return 'eventBgBonus';
            case EventKind.BUY_CURRENCY:
                return 'eventBgPurchase';
            case EventKind.STICKER_SENT:
                return event.fullscreen ? 'eventBgFullscreen' : 'eventBgSticker';
            case EventKind.MEME_CANNON_STICKER_SENT:
                return 'eventBgMemeCannon';
            default:
                return '';
        }
    };

    const getEventCellClassname = (event: IStreamerInfoStickersItem) => {
        switch (event.kind) {
            case EventKind.STICKER_SENT:
                return event.fullscreen ? 'eventFgFullscreen' : 'eventFgSticker';
            case EventKind.MEME_CANNON_STICKER_SENT:
                return 'eventFgMemeCannon';
            default:
                return '';
        }
    };

    const getEventType = (event: IStreamerInfoStickersItem) => {
        switch (event.kind) {
            case EventKind.STICKER_SENT:
                return event.fullscreen ? 'FullScreen' : t('common.sticker');
            case EventKind.BONUS_EARNED:
            case EventKind.BONUS_ISSUED:
                return t('common.bonus');
            case EventKind.BUY_CURRENCY:
                return t('common.purchase');
            case EventKind.MEME_CANNON_STICKER_SENT:
                return t('common.meme-cannon');
            case EventKind.WELCOME_BONUS_EARNED:
                return t('common.bonus');
            case EventKind.MODERATION_STATUS:
                return t('common.moderation');
            default:
                return '-';
        }
    };

    const formatDateTime = (date: Date | number) => {
        const d = typeof date === 'number' ? new Date(date) : date;

        return d.toLocaleTimeString('ru', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        });
    };

    const rows = currentStats.map((e, idx) => {
        const rowClass = getEventRowClassname(e);
        const cellClass = getEventCellClassname(e);

        return (
            <tr key={idx} className={cn(s.eventsTableBodyRow, s[rowClass])}>
                <td className={s.eventsTableBodyRowCell}>{formatDateTime(e.timestamp)}</td>
                <td className={cn(s.eventsTableBodyRowCell, s[cellClass])}>{getEventType(e)}</td>
                <td
                    className={cn(
                        s.eventsTableBodyRowCell,
                        s.eventsTableBodyRowCellClickable,
                        s[cellClass],
                    )}
                    onClick={() => goToViewersSearch(e.userId)}
                >
                    {e.userName || e.userAlias}
                </td>
                <td
                    className={cn(s.eventsTableBodyRowCell, s.eventsTableBodyRowCellClickable, s[cellClass])}
                    onClick={() => goToViewersSearch(e.userId)}
                >{e.userId}</td>
                <td
                    className={cn(s.eventsTableBodyRowCell, s.eventsTableBodyRowCellClickable, s[cellClass])}
                    onClick={() => goToStickersEditor(e.stickerId ?? '')}
                >{e.stickerName}</td>
                <td className={cn(s.eventsTableBodyRowCell, s[cellClass])}>{e.paidPrice}</td>
            </tr>
        );
    });

    return (
        <Column grow>
            <table className={s.eventsTable}>
                <thead>
                    <tr className={s.eventsTableHeadRow}>
                        <td className={s.eventsTableHeadRowCell}>
                            {t('moderator-area.streamers.view.money-table.date')}
                        </td>
                        <td className={s.eventsTableHeadRowCell}>
                            {t('moderator-area.streamers.view.money-table.type')}
                        </td>
                        <td className={s.eventsTableHeadRowCell}>
                            {t('moderator-area.streamers.view.money-table.viewer')}
                        </td>
                        <td className={s.eventsTableHeadRowCell}>
                            {t('moderator-area.streamers.view.money-table.viewer-id')}
                        </td>
                        <td className={s.eventsTableHeadRowCell}>
                            {t('moderator-area.viewers.view.events-table.sticker')}
                        </td>
                        <td className={s.eventsTableHeadRowCell}>
                            {t('moderator-area.streamers.view.money-table.coins')}
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
            {!isEndReached && currentStats.length > 0 &&
                !isLoadingPage && !isFetchingPage &&
                <Row>
                    <Button
                        caption={t('moderator-area.viewers.view.load-more')}
                        style={{ marginTop: 15 }}
                        className={s.loadMoreButton}
                        onClick={loadNextPage}
                    />
                </Row>
            }
            {!(isLoadingPage || isFetchingPage) && currentStats.length === 0 &&
                <Row>
                    <Empty
                        style={{ marginTop: 20 }}
                        icon={<IconMagnifierLine/>}
                        text={t('moderator-area.viewers.view.wallets-table.empty')}
                    />
                </Row>
            }
            {(isLoadingPage || isFetchingPage) &&
                <Row>
                    <Loader style={{ marginTop: 20, marginBottom: 3 }}/>
                </Row>
            }
        </Column>
    );
};
